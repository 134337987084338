import React, { useState } from "react";
import { Grid, Button, TextField, Modal, Box, Alert, Snackbar, CircularProgress } from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "../axios";

const url = process.env.REACT_APP_BE_URL;

let initialValues = {
  email: "",
};

let SignUpSchema = Yup.object().shape({
  email: Yup.string().email().required("Email is required!"),
});

const TitleStyles = {
  backgroundColor: "#fff",
  color: "#000",
  fontWeight: "800",
  display: "flex",
  fontSize: "2rem",
  marginBottom: "2rem",
};

const fieldStyles = {
  width: { xs: 298, sm: 398 },
};

const formStyles = {
  display: "flex",
  flexDirection: "column",
};

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 350, sm: 550 },
  bgcolor: "#fff",
  boxShadow: 20,
  p: 4,
  borderRadius: "8px",
  padding: "30px",
  border: "0px",
};

function InviteModal({ open, onClose }) {
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const [btnDisabled, setbtnDisabled] = useState(false);
  //error check 
  const [check, setCheck] = useState(false);

  //check for error and disable close
  const [backdropDesabled, setbackdropDesabled] = useState(false);


  const onModelclose = () => {

    if (!backdropDesabled) {
      setAlert({
        showAlert: false,
        severity: "success",
        message: "",
      })
      onClose();
    }
  }

  function handleClose() {
    setAlert({
      ...alert,
      showAlert: false,
      severity: "",
      message: "",
    });
  }


  const handleSubmit = async (values) => {
    setbackdropDesabled(true);
    setbtnDisabled(true)

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
            }`,
        },
      };

      const res = await axios.post(`${url}/invite`, { email: values.email }, config);

      if (res.status === 200) {

        setbtnDisabled(false)
        setbackdropDesabled(false)
        onClose()
        setTimeout(() => {
          setAlert({
            showAlert: true,
            severity: "success",
            message: "Invitation sent successfully",
          })
        }, 500);


        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "success",
            message: "",
          })

        }, 16000);

      }
    } catch (error) {
      setCheck(true)
      setbackdropDesabled(true);
      setbtnDisabled(false);
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed"}`,
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        })
        setbackdropDesabled(false);
        setCheck(false)
      }, 16000);

    }
  };



  return (
    <>
      {!check ? (<Snackbar
        mt="8vh"
        open={alert.showAlert}
        autoHideDuration={15000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          severity={alert.severity}
          onClose={handleClose}
          sx={{
            fontSize: "16px",
            overflowWrap: "break-word",
            marginTop: "4rem",
            marginRight: {
              lg: "2rem",
              md: "2rem",
              sm: "1rem",
            },
          }}
        >
          {alert.message}
        </Alert>
      </Snackbar>) : null}

      <Modal
        open={open}
        onClose={onModelclose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container justifyContent="center">
            <Grid item style={TitleStyles}>
              Invitation
            </Grid>
            <Grid container item direction="column" alignItems="center">
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={SignUpSchema}
              >
                {({ errors, isValid, touched, dirty }) => (
                  <Form style={formStyles}>
                    <Grid item>
                      <Field
                        sx={fieldStyles}
                        name="email"
                        label="Email"
                        as={TextField}
                        variant="outlined"
                        required
                        size="small"
                        margin="dense"
                        error={Boolean(errors.email) && Boolean(touched.email)}
                        helperText={Boolean(touched.email) && errors.email}
                      ></Field>
                    </Grid>

                    <Grid item>
                      <Button
                        sx={{
                          borderRadius: "8px",
                          fontSize: "1rem",
                          fontWeight: "700",
                          height: "2.5rem",
                          marginTop: "1rem",
                        }}
                        color="primary"
                        fullWidth
                        variant="contained"
                        disabled={!dirty || !isValid || btnDisabled}
                        type="submit"
                        size="large"
                      >
                        {btnDisabled ?
                          <CircularProgress size={20} />
                          :
                          'Invite'
                        }

                      </Button>
                    </Grid>

                    {check && alert.showAlert && <Grid item mt={1}>
                      <Alert severity={alert.severity}
                        onClose={handleClose}
                        sx={{
                          fontSize: "16px"
                        }}>
                        {alert.message}
                      </Alert>
                    </Grid>}
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default InviteModal;
