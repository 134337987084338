import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Modal,
  Box,
  Snackbar,
  Alert,
  CircularProgress,

} from "@mui/material";
import axios from "../axios";

import DropFileInput from "../Components/components/drop-file-input/DropFileInput";
import "../styles/styles.css";

const url = process.env.REACT_APP_BE_URL;

const formStyles = {
  display: "flex",
  flexDirection: "column",
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 350, sm: 550 },
  bgcolor: "#fff",
  boxShadow: 30,
  p: 4,
  borderRadius: "8px",
  padding: "30px",
  border: "0px",
};

function EditProjectDetailsModal2({ open, onClose, project, getData }) {
  const [check, setCheck] = useState(false);
  const [isSubmissionLoading, setSubmissionLoading] = useState(false);
  // eslint-disable-next-line
  const [openSnackbar, setOpenSnackbar] = useState(true);
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const [documentNames, setDocumentNames] = useState([])

  function handleClose() {
    setOpenSnackbar(false);
    setAlert({
      ...alert,
      showAlert: false,
      severity: "",
      message: "",
    });
  }

  const [payload, setPayload] = useState(null)

  const getAllFileNames = (names) => {
    setDocumentNames(names)
  };

  const onFileChange = async (files) => {
    setPayload(files)
  };

  useEffect(() => {
    if (payload?.length > 0) {
      setCheck(true)
    } else {
      setCheck(false)
    }
  }, [payload])


  const handleSubmit = async () => {
    setCheck(false);
    setSubmissionLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };
    try {
      const res = await axios.put(`${url}/project/${project._id}`, {
        public_id: [...project.iPSensitiveDetails, ...documentNames],
        iPSensitiveDetails: [...project.iPSensitiveDetails, ...payload]
      }, config);
      onClose()
      getData()
      if (res?.data?.name === "Error") {
        setAlert({
          showAlert: true,
          severity: "error",
          message: `${res.data?.message ? res.data.message : "Action Failed"}`
        });
      } else {
        setAlert({
          showAlert: true,
          severity: "success",
          message: `${res.data?.message ? res.data?.message : res.data ? res.data : "Document is added successfully"}`,
        });

        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "success",
            message: "",
          });
        }, 16000);

      }

    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed"}`,
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);
      onClose();
    }
    setCheck(true);
    setSubmissionLoading(false);
  };

  return (
    <>
      <Snackbar
        sx={{ mt: "8vh" }}
        open={alert.showAlert}
        autoHideDuration={15000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={alert.severity}
          sx={{
            fontSize: "16px",
          }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
      <Modal open={open} onClose={onClose}  >
        <Box sx={style}>
          <Grid container justifyContent="center">
            <Grid container item direction="column" alignItems="center">
              <form style={formStyles}>
                <Grid
                  item
                  sx={{
                    border: "1px dashed ",
                    borderRadius: "8px",
                    width: { xs: 298, sm: 398 },
                  }}
                >
                  <div className="box">
                    <h2 className="header">Documents</h2>
                    <DropFileInput
                      onFileChange={(files) => onFileChange(files)}
                      getAllFileNames={(names) => getAllFileNames(names)}
                    />
                  </div>
                </Grid>

                <Grid item>
                  <Button
                    sx={{
                      borderRadius: "8px",
                      fontSize: "1rem",
                      fontWeight: "700",
                      height: "2.5rem",
                      marginTop: "1rem",
                    }}
                    color="primary"
                    fullWidth
                    variant="contained"
                    disabled={!check || isSubmissionLoading}
                    size="large"
                    onClick={handleSubmit}
                  >
                    {isSubmissionLoading ?
                      <CircularProgress size={20} />
                      :
                      'Submit'
                    }
                  </Button>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Box>
      </Modal >
    </>
  );
}

export default EditProjectDetailsModal2;
