import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  TextField,
  InputAdornment,
  Alert,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import axiosInstance from "../axios";
import { useLocation, useNavigate } from "react-router-dom";
import Drawer from "../Components/Drawer";
import DropFileInput from "../Components/components/drop-file-input/DropFileInput";
import "../styles/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { clearFormData } from "../store/actions/projectActions";

const url = process.env.REACT_APP_BE_URL;

let CreateProject2Schema = Yup.object().shape({
  funding: Yup.number().required("Funding so far is required"),
  public_id: Yup.string(),
});

const TitleStyles = {
  backgroundColor: "#fff",
  color: "#000",
  fontWeight: "800",
  display: "flex",

  fontSize: "2rem",
  marginTop: "3rem",
  marginBottom: "3rem",
};

const fieldStyles = {
  width: { xs: 298, sm: 398 },
  marginBottom: "10px",
};

const formStyles = {
  display: "flex",
  flexDirection: "column",
};

function CreateProject2() {

  const dispatch = useDispatch()
  const data = useSelector((state) => state?.project?.projectDetails)

  const [documents, setDocuments] = useState(data?.iPSensitiveDetails)
  const [documentNames, setDocumentNames] = useState([])
  // eslint-disable-next-line
  const [open, setOpen] = useState(true);
  const [isSubmissionLoading, setSubmissionLoading] = useState(false);

  function handleClose() {
    setOpen(false);
    setAlert({
      ...alert,
      showAlert: false,
      severity: "",
      message: "",
    });
  }

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const navigate = useNavigate();
  const initialValues2 = {
    funding: "",
    public_id: "",
  };

  const getAllFileNames = (names) => {
    setDocumentNames(names)
  };

  const onFileChange = (files) => {
    setDocuments(files)
  };

  const handleSubmit2 = async (values, onSubmitProps) => {
    setSubmissionLoading(true);
    try {
      data.public_id = documentNames;
      data.funding = values.funding;
      data.iPSensitiveDetails = documents;
    } catch (err) {
      console.log(err);
    }

    data.users = [JSON.parse(localStorage.getItem("userInfo"))._id];
    data.status = "pending";
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };

    try {
      const res = await axiosInstance.post(`${url}/project`, data, config);
      if (res.status === 200) {
        setAlert({
          showAlert: true,
          severity: "success",
          message: `${res.data?.message ? res.data?.message : res.data ? res.data : "Project added successfully!"}`,
        });

        dispatch(clearFormData())


        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "success",
            message: "",
          });
        }, 16000);

        setTimeout(() => {
          navigate("/projects");
          setSubmissionLoading(false)
        }, 3000)
      }
    } catch (error) {
      setSubmissionLoading(false)
      console.log(error);
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed"}`,
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });

      }, 16000);
    }
  };

  return (
    <>
      <Drawer />
      <Grid container justifyContent="center" sx={{ paddingTop: "70px", marginBottom: "2rem" }}>
        <Snackbar
          mt="8vh"
          open={alert.showAlert}
          autoHideDuration={15000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            severity={alert.severity}
            onClose={handleClose}
            sx={{
              fontSize: "16px",
              overflowWrap: "break-word",
              marginTop: "4rem",
              marginRight: {
                lg: "2rem",
                md: "2rem",
                sm: "1rem",
              },
            }}
          >
            {alert.message}
          </Alert>
        </Snackbar>

        <Grid item style={TitleStyles}>
          Private Details
        </Grid>
        <Grid container item direction="column" alignItems="center">
          <Formik
            initialValues={initialValues2}
            onSubmit={handleSubmit2}
            validationSchema={CreateProject2Schema}
          >
            {({ errors, isValid, touched, dirty }) => (
              <Form style={formStyles}>
                <Grid item>
                  <Field
                    sx={fieldStyles}
                    name="funding"
                    label={"Funding so far"}
                    as={TextField}
                    variant="outlined"
                    required
                    size="small"
                    type="number"
                    margin="dense"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    error={Boolean(errors.funding) && Boolean(touched.funding)}
                    helperText={Boolean(touched.funding) && errors.funding}
                  ></Field>
                </Grid>
                <Grid
                  item
                  sx={{
                    border: "1px dashed ",
                    borderRadius: "8px",
                    width: { xs: 298, sm: 398 },
                  }}
                >
                  <div className="box">
                    <h2 className="header">Documents</h2>
                    <DropFileInput
                      onFileChange={(files) => onFileChange(files)}
                      getAllFileNames={(names) => getAllFileNames(names)}
                    />
                  </div>
                </Grid>

                <Grid item>
                  <Button
                    sx={{
                      borderRadius: "8px",
                      fontSize: "1rem",
                      fontWeight: "700",
                      height: "2.5rem",
                      marginTop: "1rem",
                    }}
                    color="primary"
                    fullWidth
                    variant="contained"
                    disabled={!dirty || !isValid || isSubmissionLoading}
                    type="submit"
                    size="large"
                  >
                    {isSubmissionLoading ?
                      <CircularProgress size={20} />
                      :
                      'Submit'
                    }

                  </Button>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </>
  );
}

export default CreateProject2;
