import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  TextField,
  Modal,
  Box,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "../axios";

const url = process.env.REACT_APP_BE_URL;
const regexp = /^\S*$/; // a string consisting only of non-whitespaces
let linksSchema = Yup.object().shape({

  linkDescription: Yup.string()
    .required("External link description is required")
    .min(3, "Too short")
    .max(25, "Too long"),
  links: Yup.string().required("Link is required").matches(regexp, 'Single link only')
});

const TitleStyles = {
  backgroundColor: "#fff",
  color: "#000",
  fontWeight: "800",
  display: "flex",
  fontSize: "2rem",
  marginTop: "1rem",
  marginBottom: "1rem",
};

const fieldStyles = {
  width: { xs: 298, sm: 398 },
  marginBottom: "10px",
};

const formStyles = {
  display: "flex",
  flexDirection: "column",
};

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 350, sm: 550 },
  bgcolor: "#fff",
  boxShadow: 30,
  p: 4,
  borderRadius: "8px",
  padding: "30px",
  border: "0px",
};

function Links({
  open,
  onClose,
  isEditData,
  setIndex,
  index,
  setIsEditData,
  data,
  getData,
}) {
  const [initialValues, setInitialValues] = useState({
    linkDescription: "",
    links: "",
  });

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  // eslint-disable-next-line
  const [openSnackbar, setOpenSnackbar] = useState(true);
  const [isSubmissionLoading, setSubmissionLoading] = useState(false);

  useEffect(() => {
    if (isEditData) {
      setInitialValues({
        linkDescription: data?.links[index]?.linkDescription,
        links: data?.links[index]?.links,
      });
    } else {
      setInitialValues({
        linkDescription: "",
        links: "",
      });
    }
  }, [data, index, isEditData]);

  function handleClose() {
    setOpenSnackbar(false);
    setAlert({
      ...alert,
      showAlert: false,
      severity: "",
      message: "",
    });
  }

  const items = JSON.parse(localStorage.getItem("userInfo"));

  const handleSubmit = async (values, { resetForm }) => {
    setSubmissionLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };

    try {
      let payLoad;
      if (!isEditData) {
        payLoad = {
          links: [...data.links, values],
        };
      } else {
        data.links[index] = values;
        payLoad = {
          links: data.links,
        };
      }

      const { status } = await axios.put(
        `${url}/user/${items._id}`,
        payLoad,
        config
      );
      resetForm();
      onClose();
      setIsEditData(false);
      getData();

      if (status === 200 && isEditData) {
        setAlert({
          showAlert: true,
          severity: "success",
          message: "External Links are edited successfully!",
        });

        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "success",
            message: "",
          });
        }, 16000);
      } else if (status === 200 && !isEditData) {
        setAlert({
          showAlert: true,
          severity: "success",
          message: "External Links are added successfully!",
        });

        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "success",
            message: "",
          });
        }, 16000);
      }
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed"}`,
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);
    }
    setSubmissionLoading(false);
  };

  return (
    <>
      <Snackbar
        sx={{ mt: "8vh" }}
        open={alert.showAlert}
        autoHideDuration={15000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={alert.severity}
          sx={{
            fontSize: "16px",
          }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
      <Modal open={open} onClose={onClose}>
        <Box sx={style}>
          <Grid container justifyContent="center">
            <Grid item style={TitleStyles}>
              External Links
            </Grid>
            <Grid container item direction="column" alignItems="center">
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={linksSchema}
              >
                {({ errors, isValid, touched, dirty }) => (
                  < Form style={formStyles}>
                    <Grid item>
                      <Field
                        sx={fieldStyles}
                        name="linkDescription"
                        label="External Link Description (e.g. LinkedIn Profile)"
                        as={TextField}
                        variant="outlined"
                        required
                        size="small"
                        margin="dense"
                        error={
                          Boolean(errors.linkDescription) &&
                          Boolean(touched.linkDescription)
                        }
                        helperText={
                          Boolean(touched.linkDescription) && errors.linkDescription
                        }
                      ></Field>
                    </Grid>
                    <Grid item>
                      <Field
                        sx={fieldStyles}
                        name="links"
                        label="Link"
                        as={TextField}
                        variant="outlined"
                        multiline
                        rows={4}
                        required
                        size="small"
                        margin="dense"
                        error={Boolean(errors.links) && Boolean(touched.links)}
                        helperText={Boolean(touched.links) && errors.links}
                      ></Field>
                    </Grid>

                    <Grid item>
                      <Button
                        sx={{
                          borderRadius: "8px",
                          fontSize: "1rem",
                          fontWeight: "700",
                          height: "2.5rem",
                          marginTop: "1rem",
                        }}
                        color="primary"
                        fullWidth
                        variant="contained"
                        disabled={!isValid || isSubmissionLoading}
                        type="submit"
                        size="large"
                      >
                        {isSubmissionLoading ?
                          <CircularProgress size={20} />
                          :
                          'Submit'
                        }
                      </Button>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default Links;
