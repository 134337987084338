import React, { useState } from "react";
import {
  Grid,
  Button,
  TextField,
  Modal,
  Box,
  InputAdornment,
  Snackbar,
  Alert,
  Select,
  FormControl,
  InputLabel,
  CircularProgress
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "../axios";
import MenuItem from '@mui/material/MenuItem';

const url = process.env.REACT_APP_BE_URL;

let CreateProject1Schema = Yup.object().shape({
  projectName: Yup.string()
    .required("Project Name is required")
    .min(3, "Too short")
    .max(20, "Too long"),

  industry: Yup.string()
    .required("Industry is required"),

  description: Yup.string()
    .required("Project Description is required")
    .min(100, "Too short")
    .max(5000, "Too long"),
  funding: Yup.number().required("Funding so far is required"),
});

const TitleStyles = {
  backgroundColor: "#fff",
  color: "#000",
  fontWeight: "800",
  display: "flex",

  fontSize: "2rem",
  marginTop: "1rem",
  marginBottom: "1rem",
};

const fieldStyles = {
  width: { xs: 298, sm: 398 },
  marginBottom: "10px",
};

const formStyles = {
  display: "flex",
  flexDirection: "column",
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 350, sm: 550 },
  bgcolor: "#fff",
  boxShadow: 30,
  p: 4,
  borderRadius: "8px",
  padding: "30px",
  border: "0px",
};

//select formik setup
const CustomizedSelectForFormik = ({ children, form, field }) => {
  const { name, value } = field;
  const { setFieldValue } = form;

  return (
    <Select
      // required
      label="industry"
      name={name}
      value={value}
      onChange={e => {
        setFieldValue(name, e.target.value);
      }}
    >
      {children}
    </Select>
  );
};

function EditProjectDetailsModal1({
  open,
  onClose,
  project,
  getData,
  initialValues1,
}) {
  const [check, setCheck] = useState(false);
  // eslint-disable-next-line
  const [openSnackbar, setOpenSnackbar] = useState(true);
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  function handleClose() {
    setOpenSnackbar(false);
    setAlert({
      ...alert,
      showAlert: false,
      severity: "",
      message: "",
    });
  }

  const handleSubmit1 = async (values) => {
    setCheck(true);
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };

    try {
      let payload = {
        name: values.projectName,
        industry: values.industry,
        description: values.description,
        funding: values.funding,
      };

      const res = await axios.put(
        `${url}/project/${project._id}`,
        payload,
        config
      );

      onClose();
      getData();

      if (res.status === 200) {
        setAlert({
          showAlert: true,
          severity: "success",
          message: `${res.data?.message ? res.data?.message : res.data ? res.data : "Project details are updated successfully"}`,
        });

        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "success",
            message: "",
          });
        }, 16000);
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: `${res.data?.message ? res.data.message : "Action Failed"}`
        });
      }
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed"}`,
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);
      onClose();
    }
    setCheck(false);
  };

  return (
    <>
      <Snackbar
        sx={{ mt: "8vh" }}
        open={alert.showAlert}
        autoHideDuration={15000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={alert.severity}
          sx={{
            fontSize: "16px",
          }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
      <Modal open={open} onClose={onClose}>
        <Box sx={style}>
          <Grid container justifyContent="center">
            <Grid item style={TitleStyles}>
              Public Details
            </Grid>
            <Grid container item direction="column" alignItems="center">
              <Formik
                initialValues={initialValues1}
                onSubmit={handleSubmit1}
                validationSchema={CreateProject1Schema}
              >
                {({ errors, isValid, touched, dirty }) => (
                  <Form style={formStyles}>
                    <Grid item>
                      <Field
                        sx={fieldStyles}
                        name="projectName"
                        label={"Project Name"}
                        as={TextField}
                        variant="outlined"
                        required
                        size="small"
                        margin="dense"
                        error={
                          Boolean(errors.projectName) &&
                          Boolean(touched.projectName)
                        }
                        helperText={
                          Boolean(touched.projectName) && errors.projectName
                        }
                      ></Field>
                    </Grid>
                    <Grid item>
                      <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth required>
                          <InputLabel
                            id="demo-simple-select-label"
                            name="industry"
                          >
                            Industry
                          </InputLabel>

                          <Field
                            component={CustomizedSelectForFormik}
                            name="industry"
                            as={Select}

                            error={
                              Boolean(errors.industry) && Boolean(touched.industry)
                            }
                            helperText={Boolean(touched.industry) && errors.industry} >
                            <MenuItem value="Consumer Discretionary">Consumer Discretionary</MenuItem>
                            <MenuItem value="Consumer Staples">Consumer Staples</MenuItem>
                            <MenuItem value="Construction">Construction</MenuItem>
                            <MenuItem value="Energy">Energy</MenuItem>
                            <MenuItem value="Materials">Materials</MenuItem>
                            <MenuItem value="Industrials">Industrials</MenuItem>
                            <MenuItem value="Healthcare">Healthcare</MenuItem>
                            <MenuItem value="Financials">Financials</MenuItem>
                            <MenuItem value="Information Technology">Information Technology</MenuItem>
                            <MenuItem value="Real Estate">Real Estate</MenuItem>
                            <MenuItem value="Communication Services">Communication Services</MenuItem>
                            <MenuItem value="Utilities">Utilities</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                          </Field>
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Field
                        sx={fieldStyles}
                        name="description"
                        label="Project Description (Min. 100 characters)"
                        as={TextField}
                        variant="outlined"
                        size="small"
                        margin="dense"
                        multiline
                        rows={10}
                        error={
                          Boolean(errors.description) &&
                          Boolean(touched.description)
                        }
                        helperText={
                          Boolean(touched.description) && errors.description
                        }
                      ></Field>
                    </Grid>
                    <Grid item>
                      <Field
                        sx={fieldStyles}
                        name="funding"
                        label={"Funding so far"}
                        as={TextField}
                        variant="outlined"
                        required
                        size="small"
                        type="number"
                        margin="dense"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        error={
                          Boolean(errors.funding) && Boolean(touched.funding)
                        }
                        helperText={Boolean(touched.funding) && errors.funding}
                      ></Field>
                    </Grid>

                    <Grid item>
                      <Button
                        sx={{
                          borderRadius: "8px",
                          fontSize: "1rem",
                          fontWeight: "700",
                          height: "2.5rem",
                          marginTop: "1rem",
                        }}
                        color="primary"
                        fullWidth
                        variant="contained"
                        disabled={!dirty || !isValid || check}
                        type="submit"
                        size="large"
                      >
                        {check ?
                          <CircularProgress size={20} />
                          :
                          'Submit'
                        }

                      </Button>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default EditProjectDetailsModal1;
