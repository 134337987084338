import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Grid,
  Snackbar,
} from "@mui/material";
import axios from "../axios";
import { useNavigate } from 'react-router-dom';

import CircularProgress from "@mui/material/CircularProgress";
import Navbar from "../Components/Navbar";
import Sidebar from "../Components/Sidebar";
import { useParams } from "react-router-dom";
// import axiosInstance from "../axios";
import EqutipageInviteModal from "./EqutipageInviteModal";
import { Pie } from "react-chartjs-2";
// eslint-disable-next-line
import { Chart as ChartJS } from "chart.js/auto";
import { Chart, Legend } from 'chart.js';
import { chartColors } from "../Components/colors";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import EditEquityTableModel from "./EditEquityTableModel"

Chart.register(ChartDataLabels, Legend); // REGISTER PLUGIN
Chart.defaults.font.size = 15;
const url = process.env.REACT_APP_BE_URL;

const EquityDetails = () => {

  const userID = JSON.parse(localStorage.getItem("userInfo"))._id;
  const { projId } = useParams();
  const navigate = useNavigate();

  const [openPublishmodel, setPublishmodel] = useState(false);

  const [chartDetails, setChartDetails] = useState(null);
  const [isaAdmin, setIsaAdmin] = useState(false);

  // eslint-disable-next-line
  const [openSnackbar, setOpenSnackbar] = useState(true);
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  //models states
  const [openEditEquityModel, setOpenEditEquityModel] = useState(false);
  const [project, setProject] = useState(null);
  const [equityDetails, setequityDetails] = useState(null);

  const [userDetails, setuserDetails] = useState(null);
  const [pieData, setpieData] = useState(null);


  // check if user in admins array
  const logedInUser = JSON.parse(localStorage.getItem("userInfo"));
  const findUserinArray = () => {

    if (!project.admins.find(item => item === logedInUser._id)) {
      setIsaAdmin(false)
    } else {
      setIsaAdmin(true)

    }

  }


  //model close
  const CloseEditEquityModel = () => {
    setOpenEditEquityModel(false);
  };
  const getProjData = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token}`,
      },
    };
    try {
      const { data, status } = await axios.get(
        `${url}/project/${projId}`,
        config
      );
      if (status === 200) {
        setProject(data);
      }

    } catch (error) {
      console.log(error)
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${error.response?.data?.message ? error.response?.data?.message : error.response?.data ? error.response?.data : "Action Failed"}`,
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);
    }
  };


  const getEquity = async () => {
    if(project?.equityID.length > 0){
    let equityArr = [];
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };
    try {
      await project.equityID?.forEach(async (equityID, index) => {
        const { data, status } = await axios.get(`${url}/equity/${equityID}`, config);
        if (status === 200) {
          equityArr.push(data)

          if (project.equityID.length === equityArr.length)
            return setequityDetails(equityArr);
        }
      });
    } catch (error) {
      console.log(error)
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${error.response?.data?.message ? error.response?.data?.message : error.response?.data ? error.response?.data : "Action Failed"}`,
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);
    }
  }
  };

  function handleClose() {
    setOpenSnackbar(false);
    setAlert({
      ...alert,
      showAlert: false,
      severity: "",
      message: "",
    });
  }

  const getUser = async () => {
    let userArr = [];
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };

    try {
      await equityDetails?.forEach(async (userID, index) => {
        const { data, status } = await axios.get(`${url}/user/${userID?.userID}`, config);
        if (status === 200) {
          userArr.push(data)
          if (equityDetails?.length === userArr?.length) {
            return setuserDetails(userArr);
          }

        }
      });
    } catch (error) {
      console.log(error)
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${error.response?.data?.message ? error.response?.data?.message : error.response?.data ? error.response?.data : "Action Failed"}`,
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);
    }
  };

  const setChardata = () => {

    let mapChartDetails = [{ firstName: "unassignedUnits", units: project?.unassignedUnits }]

    equityDetails?.forEach((data) => {
      if (data) {
        mapChartDetails.push({
          ...data,
          firstName: userDetails.find(item => item?._id === data?.userID)?.firstName,
          lastName: userDetails.find(item => item?._id === data?.userID)?.lastName
        })

        if (mapChartDetails?.length === equityDetails?.length + 1) {
          return setChartDetails(mapChartDetails)
        }
      } else {
        return setChartDetails(mapChartDetails)
      }
    })

    setpieData({
      maintainAspectRatio: true,
      responsive: true,
      labels: chartDetails ? chartDetails.map(item => item?.firstName) : null,
      datasets: [
        {
          data: chartDetails ? chartDetails.map(item => item?.units) : null,
          backgroundColor: chartColors,
          hoverBackgroundColor: chartColors,
          datalabels: {
            color: '#000000',
            textStrokeColor: "#ffff",
            textStrokeWidth: "6",
          }
        },
      ],
    });
  }
  const options = {
    indexAxis: 'x',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
    },

  };

  const adminCheck = project?.admins?.filter((id) => {
    return userID === id;
  });

  const checkProjectNull = project === null

  useEffect(() => {
    getProjData();

    // eslint-disable-next-line
  }, [checkProjectNull]);

  useEffect(() => {
    if (project) {
      getEquity();
      findUserinArray();
    }
    // eslint-disable-next-line
  }, [project]);

  useEffect(() => {
    if (equityDetails) getUser();
    setpieData();
    // eslint-disable-next-line
  }, [equityDetails, project]);

  useEffect(() => {
    if (userDetails) setChardata();
    // eslint-disable-next-line
  }, [userDetails, project]);

  useEffect(() => {
    if(process.env.REACT_APP_COMMUNITY_EDITION === 'true'){
    navigate(`/projectdetails/${projId}`);
  }
    setChardata();
    // eslint-disable-next-line
  }, []);

  return (
    <>

      <Navbar />
      <Snackbar
        sx={{ mt: "8vh" }}
        open={alert.showAlert}
        autoHideDuration={15000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={alert.severity}
          sx={{
            fontSize: "16px",
          }}
        >
          {alert.message}
        </Alert>
      </Snackbar>

      <Grid container>

        <Grid container item
          lg={1}
          md={1.5}
          sm={1.8}
          xs={1}
        >
          <Sidebar />
        </Grid>

        <EqutipageInviteModal
          projId={projId}
          openPublishmodel={openPublishmodel}
          setPublishmodel={setPublishmodel}
        />

        <EditEquityTableModel
          open={openEditEquityModel}
          onClose={CloseEditEquityModel}
          tableDetails={chartDetails}
          getEquity={getEquity}
          setChardata={setChardata}
          getUser={getUser}
          setpieData={setpieData}
          getProjData={getProjData}
          projectData={project}
        />
        {!pieData ? (
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            style={{ height: "100vh" }}
          >
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : (
          <Grid container item
            marginTop={!isaAdmin ? 19 : 11}
            lg={10.5}
            md={9.8}
            sm={10}
            xs={10}
            alignItems="center"
            justifyContent={{
              lg: "flex-end",
              md: "flex-end",
              sm: "center",
              xs: "center",
            }}
          >
            {isaAdmin &&
              <Grid container item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                justifyContent={{
                  lg: "flex-end",
                  md: "flex-end",
                  sm: "flex-end",
                  xs: "center",
                }}
                marginBottom={{
                  lg: "1rem",
                  md: "1rem",
                  sm: "1rem",
                  xs: "1rem",
                }}
              >
                {adminCheck?.length > 0 && project.status === "pending" ? (
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: "8px",
                      textTransform: "none",
                      marginLeft: {
                        lg: "1rem",
                        md: "1rem",
                        sm: "1rem",
                        xs: "0.5rem",
                      },
                      marginRight: {
                        lg: "0rem",
                        md: "0rem",
                        sm: "0rem",
                        xs: "0.5rem",
                      },
                      marginTop: {
                        lg: "1rem",
                        md: "1rem",
                        sm: "1rem",
                        xs: "1rem",
                      },
                      marginBottom: {
                        lg: "1rem",
                        md: "1rem",
                        sm: "1rem",
                        xs: "1rem",
                      },
                    }}
                    onClick={() => setPublishmodel(true)}
                  >
                    Invite collaborators
                  </Button>) : ("")}

                <Button
                  color="success"
                  variant="contained"
                  sx={{
                    borderRadius: "8px",
                    textTransform: "none",
                    marginLeft: {
                      lg: "1rem",
                      md: "1rem",
                      sm: "1rem",
                      xs: "0.5rem",
                    },
                    marginRight: {
                      lg: "0rem",
                      md: "0rem",
                      sm: "0rem",
                      xs: "0.5rem",
                    },
                    marginTop: {
                      lg: "1rem",
                      md: "1rem",
                      sm: "1rem",
                      xs: "1rem",
                    },
                    marginBottom: {
                      lg: "1rem",
                      md: "1rem",
                      sm: "1rem",
                      xs: "1rem",
                    },
                  }}

                  onClick={() => {
                    setOpenEditEquityModel(true)
                  }} >
                  Edit Equity Percentages
                </Button>
              </Grid>
            }

            <Grid
              container
              alignItems="center"
              justifyContent="center" >
              <Grid item
                lg={5}
                md={9}
                sm={12}
                xs={12}
                sx={{
                  mt: {
                    lg: "0vw",
                    md: "0vw",
                    sm: "3vw",
                    xs: "10vw",
                  }
                }}
              >
                {pieData && <Pie data={pieData ? pieData : {
                  maintainAspectRatio: true,
                  responsive: true,
                  labels: ["loading..."],
                  datasets: [
                    {
                      data: ["loading..."],
                      backgroundColor: null,
                      hoverBackgroundColor: null,
                    },
                  ],
                }}

                  options={options}
                />}
              </Grid>
            </Grid>
          </Grid>)}

      </Grid>
    </>
  );
};

export default EquityDetails;
