import React, { useState } from "react";
import { Grid, Button, TextField, Dialog, DialogContent, Alert, Snackbar, Box, CircularProgress } from "@mui/material";
import { Formik, Form, Field } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import * as Yup from "yup";
import axios from "../axios";
const url = process.env.REACT_APP_BE_URL;

let initialValues = {
    jobName: "",
};

let DuplicateJobSchema = Yup.object().shape({
    jobName: Yup.string().required("Job name is required!"),
});

function DuplicateJobModal({ open, onClose, duplicateJobDetails, refreshPage, setAlert }) {
    //button disable
    const [btnDisabled, setBtnDisabled] = useState(false);

    function handleClose() {
        setAlert({
            ...alert,
            showAlert: false,
            severity: "",
            message: "",
        });
        onClose()
    }

    const handleSubmit = async (values) => {
        setBtnDisabled(true)
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
                    }`,
            },
        };
        try {
            const newJob = { ...duplicateJobDetails, title: values?.jobName }
            const res = await axios.post(`${url}/job`, newJob, config);

            if (res.status === 200) {
                setBtnDisabled(false)
                refreshPage();
                setAlert({
                    showAlert: true,
                    severity: "success",
                    message: `${res.data}`,
                })
                onClose();
            }
        } catch (error) {
            setBtnDisabled(false);
            setAlert({
                showAlert: true,
                severity: "error",
                message: `${error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed"}`,
            });
        }
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={() => handleClose()}
                maxWidth="sm"
                fullWidth
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Grid item xs={12} sm={12} md={12}>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <CloseIcon
                            style={{ cursor: "pointer", margin: "0.7rem" }}
                            onClick={() => handleClose()}
                        />
                    </Box>
                </Grid>
                <DialogContent style={{ margin: '0 2rem 0 2rem' }}>
                    <Grid container justifyContent="center">
                        <Grid item sx={{
                            backgroundColor: "#fff",
                            color: "#000",
                            fontWeight: "800",
                            display: "flex",
                            fontSize: { lg: "2rem", sm: "1rem" },
                            marginBottom: "2rem",
                        }}>
                            Create Job
                        </Grid>
                        <Grid container item direction="column" alignItems="center">
                            <Formik
                                initialValues={initialValues}
                                onSubmit={handleSubmit}
                                validationSchema={DuplicateJobSchema}
                            >
                                {({ errors, isValid, touched, dirty }) => (
                                    <Form sx={{ display: "flex", flexDirection: "column" }}>
                                        <Grid item>
                                            <Field
                                                sx={{ width: { xs: 220, sm: 400, md: 500, lg: 400 }, }}
                                                name="jobName"
                                                label="Job name"
                                                as={TextField}
                                                variant="outlined"
                                                required
                                                size="small"
                                                margin="dense"
                                                error={Boolean(errors.jobName) && Boolean(touched.jobName)}
                                                helperText={Boolean(touched.jobName) && errors.jobName}
                                            ></Field>
                                        </Grid>

                                        <Grid item>
                                            <Button
                                                sx={{
                                                    borderRadius: "8px",
                                                    fontSize: "1rem",
                                                    fontWeight: "700",
                                                    height: "2.5rem",
                                                    marginTop: "1rem",
                                                }}
                                                color="primary"
                                                fullWidth
                                                variant="contained"
                                                disabled={!dirty || !isValid || btnDisabled}
                                                type="submit"
                                                size="large"
                                            >
                                                {btnDisabled ?
                                                    <CircularProgress size={20} />
                                                    :
                                                    'Create'
                                                }
                                            </Button>
                                        </Grid>
                                    </Form>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default DuplicateJobModal;
