import React, { useState } from "react";
import { Grid, Button, Modal, Box, Typography, Chip, CircularProgress } from "@mui/material";
import axios from "../axios";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import CheckIcon from "@mui/icons-material/Check";

const url = process.env.REACT_APP_BE_URL;

const TitleStyles = {
    backgroundColor: "#fff",
    color: "#000",
    fontWeight: "800",
    display: "flex",
    fontSize: "1.5rem",
    marginBottom: "2rem",
};

const style = {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
        lg: 400,
        md: 400,
        sm: 400,
        xs: 350,
    },
    bgcolor: "#fff",
    boxShadow: 20,
    p: 4,
    borderRadius: "8px",
    padding: "30px",
    border: "0px",
};

const listStyle = {
    marginBottom: {
        lg: "0.5rem",
        md: "0.5rem",
        sm: "0.5rem",
        xs: "1rem",
    },
}

function RequestModal({ open, onClose, data, callBackDoadData, setMessage }) {

    const [isSubmissionLoading, setSubmissionLoading] = useState(false);
    const [submissionType, setSubmissionType] = useState(null);
    const userID = JSON.parse(localStorage.getItem("userInfo"))._id
    const handleRequestApproalOrReject = (result) => {
        setSubmissionLoading(true);
        setSubmissionType(result);
        if (result === "delete") {
            const config = {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token}`,
                },
            };
            axios.delete(`${url}/request/${data._id}`, config).then(response => {
                setSubmissionLoading(false);
                setMessage(response)
                callBackDoadData()
                onClose();
            }).catch(e => {
                setSubmissionLoading(false);
                setMessage(e)
                callBackDoadData()
                onClose();
            });
        } else {
            let payload = { userID: userID, message: result }
            const config = {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token}`,
                },
            };
            axios.put(`${url}/request/${data._id}`, payload, config).then(response => {
                setSubmissionLoading(false);
                setMessage(response)
                callBackDoadData()
                onClose();
            }).catch(e => {
                setMessage(`${e.response.data.message ? e.response.data.message : e.response.data ? e.response.data : "Action Failed"}`)
                callBackDoadData()
                onClose();
                setSubmissionLoading(false);
            });
        }
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                {data ?
                    <Grid container justifyContent="center">
                        <Grid item style={TitleStyles}>
                            {data?.projectName}
                        </Grid>
                        <Grid container item sx={listStyle}>
                            <Grid item lg={4} md={5} sm={5} xs={4.5}>
                                <Typography

                                    sx={{
                                        paddingLeft: {
                                            lg: "1rem",
                                            md: "1rem",
                                            sm: "1rem",
                                            xs: "1rem",
                                        },
                                        fontSize: {
                                            lg: "0.8rem",
                                            md: "0.8rem",
                                            sm: "0.8rem",
                                            xs: "0.8rem",
                                        }
                                    }}>
                                    <ul>
                                        <li>Request Type</li>
                                    </ul>
                                </Typography>
                            </Grid>
                            <Grid item lg={0.5} md={0.5} sm={0.5} xs={0.5}>
                                <Typography
                                    sx={{
                                        fontSize: {
                                            lg: "0.8rem",
                                            md: "0.8rem",
                                            sm: "0.8rem",
                                            xs: "0.8rem",
                                        },
                                        wordBreak: "break-all",
                                    }}>-</Typography>
                            </Grid>
                            <Grid item lg={7.5} md={6.5} sm={6.5} xs={7}>
                                <Typography
                                    sx={{
                                        fontSize: {
                                            lg: "0.8rem",
                                            md: "0.8rem",
                                            sm: "0.8rem",
                                            xs: "0.8rem",
                                        }
                                    }}>{data.type}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container item sx={{
                            marginBottom: {
                                lg: "0.3rem",
                                md: "0.3rem",
                                sm: "0.3rem",
                                xs: "0.7rem",
                            }
                        }}>
                            <Grid item lg={4} md={5} sm={5} xs={4.5}>
                                <Typography

                                    sx={{
                                        paddingLeft: {
                                            lg: "1rem",
                                            md: "1rem",
                                            sm: "1rem",
                                            xs: "1rem",
                                        },
                                        fontSize: {
                                            lg: "0.8rem",
                                            md: "0.8rem",
                                            sm: "0.8rem",
                                            xs: "0.8rem",
                                        }
                                    }}>
                                    <ul>
                                        <li>Status</li>
                                    </ul>
                                </Typography>
                            </Grid>
                            <Grid item lg={0.5} md={0.5} sm={0.5} xs={0.5}>
                                <Typography
                                    sx={{
                                        fontSize: {
                                            lg: "0.8rem",
                                            md: "0.8rem",
                                            sm: "0.8rem",
                                            xs: "0.8rem",
                                        },
                                        wordBreak: "break-all",
                                    }}>-</Typography>
                            </Grid>
                            <Grid item lg={7.5} md={6.5} sm={6.5} xs={7}>
                                <Chip
                                    sx={{
                                        padding: "10px 5px 10px 5px",
                                        marginTop: "-8px",
                                        fontWeight: 400,
                                        fontSize: {
                                            lg: "0.8rem",
                                            md: "0.8rem",
                                            sm: "0.8rem",
                                            xs: "0.8rem",
                                        }
                                    }}
                                    size="small"
                                    label={data.status}
                                    color={
                                        data.status === "pending"
                                            ? "warning"
                                            : "success"
                                    }
                                    icon={
                                        data.status === "pending" ? (
                                            <PriorityHighIcon />
                                        ) : (
                                            <CheckIcon />
                                        )
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Grid container item sx={listStyle}>
                            <Grid item lg={4} md={5} sm={5} xs={4.5}>
                                <Typography

                                    sx={{
                                        paddingLeft: {
                                            lg: "1rem",
                                            md: "1rem",
                                            sm: "1rem",
                                            xs: "1rem",
                                        },
                                        fontSize: {
                                            lg: "0.8rem",
                                            md: "0.8rem",
                                            sm: "0.8rem",
                                            xs: "0.8rem",
                                        }
                                    }}>
                                    <ul>
                                        <li>Approval Count</li>
                                    </ul>
                                </Typography>
                            </Grid>
                            <Grid item lg={0.5} md={0.5} sm={0.5} xs={0.5}>
                                <Typography
                                    sx={{
                                        fontSize: {
                                            lg: "0.8rem",
                                            md: "0.8rem",
                                            sm: "0.8rem",
                                            xs: "0.8rem",
                                        },
                                        wordBreak: "break-all",
                                    }}>-</Typography>
                            </Grid>
                            <Grid item lg={7.5} md={6.5} sm={6.5} xs={7}>
                                <Typography
                                    sx={{
                                        fontSize: {
                                            lg: "0.8rem",
                                            md: "0.8rem",
                                            sm: "0.8rem",
                                            xs: "0.8rem",
                                        }
                                    }}>{data.approvedCount?.length}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container item sx={listStyle}>
                            <Grid item lg={4} md={5} sm={5} xs={4.5}>
                                <Typography

                                    sx={{
                                        paddingLeft: {
                                            lg: "1rem",
                                            md: "1rem",
                                            sm: "1rem",
                                            xs: "1rem",
                                        },
                                        fontSize: {
                                            lg: "0.8rem",
                                            md: "0.8rem",
                                            sm: "0.8rem",
                                            xs: "0.8rem",
                                        }
                                    }}>
                                    <ul>
                                        <li>Reject Count</li>
                                    </ul>
                                </Typography>
                            </Grid>
                            <Grid item lg={0.5} md={0.5} sm={0.5} xs={0.5}>
                                <Typography
                                    sx={{
                                        fontSize: {
                                            lg: "0.8rem",
                                            md: "0.8rem",
                                            sm: "0.8rem",
                                            xs: "0.8rem",
                                        },
                                        wordBreak: "break-all",
                                    }}>-</Typography>
                            </Grid>
                            <Grid item lg={7.5} md={6.5} sm={6.5} xs={7}>
                                <Typography
                                    sx={{
                                        fontSize: {
                                            lg: "0.8rem",
                                            md: "0.8rem",
                                            sm: "0.8rem",
                                            xs: "0.8rem",
                                        }
                                    }}>{data.rejectedApprovals?.length}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container item sx={listStyle}>
                            <Grid item lg={4} md={5} sm={5} xs={4.5}>
                                <Typography

                                    sx={{
                                        paddingLeft: {
                                            lg: "1rem",
                                            md: "1rem",
                                            sm: "1rem",
                                            xs: "1rem",
                                        },
                                        fontSize: {
                                            lg: "0.8rem",
                                            md: "0.8rem",
                                            sm: "0.8rem",
                                            xs: "0.8rem",
                                        }
                                    }}>
                                    <ul>
                                        <li>Required Count</li>
                                    </ul>
                                </Typography>
                            </Grid>
                            <Grid item lg={0.5} md={0.5} sm={0.5} xs={0.5}>
                                <Typography
                                    sx={{
                                        fontSize: {
                                            lg: "0.8rem",
                                            md: "0.8rem",
                                            sm: "0.8rem",
                                            xs: "0.8rem",
                                        },
                                        wordBreak: "break-all",
                                    }}>-</Typography>
                            </Grid>
                            <Grid item lg={7.5} md={6.5} sm={6.5} xs={7}>
                                <Typography
                                    sx={{
                                        fontSize: {
                                            lg: "0.8rem",
                                            md: "0.8rem",
                                            sm: "0.8rem",
                                            xs: "0.8rem",
                                        }
                                    }}>{data?.reqApprovCount.length}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container item sx={listStyle}>
                            <Grid item lg={4} md={5} sm={5} xs={4.5}>
                                <Typography

                                    sx={{
                                        paddingLeft: {
                                            lg: "1rem",
                                            md: "1rem",
                                            sm: "1rem",
                                            xs: "1rem",
                                        },
                                        fontSize: {
                                            lg: "0.8rem",
                                            md: "0.8rem",
                                            sm: "0.8rem",
                                            xs: "0.8rem",
                                        }
                                    }}>
                                    <ul>
                                        <li>Changed Data</li>
                                    </ul>
                                </Typography>
                            </Grid>
                            <Grid item lg={0.5} md={0.5} sm={0.5} xs={0.5}>
                                <Typography
                                    sx={{
                                        fontSize: {
                                            lg: "0.8rem",
                                            md: "0.8rem",
                                            sm: "0.8rem",
                                            xs: "0.8rem",
                                        },
                                        wordBreak: "break-all",
                                    }}>-</Typography>
                            </Grid>
                            <Grid item lg={7.5} md={6.5} sm={6.5} xs={7}>
                                <Typography
                                    maxHeight='150px'
                                    overflow='auto'
                                    sx={{
                                        fontSize: {
                                            lg: "0.8rem",
                                            md: "0.8rem",
                                            sm: "0.8rem",
                                            xs: "0.8rem",
                                        },
                                        wordBreak: "break-all",
                                    }}>{JSON.stringify(data?.changeData)}</Typography>
                            </Grid>
                        </Grid>
                        {data.status === "pending" && data.reqUserID === userID ?
                            <Grid container item sx={{ marginTop: "20px" }}>
                                <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                                    <Button
                                        onClick={() => handleRequestApproalOrReject("delete")}
                                        sx={{
                                            backgroundColor: "red",
                                            color: "#ffffff",
                                            ":hover": {
                                                bgcolor: "#000",
                                                color: "#fff",
                                            },
                                            textTransform: "none",
                                            padding: "5px 25px 5px 25px"
                                        }}
                                        disabled={isSubmissionLoading}
                                    >
                                        {isSubmissionLoading ?
                                            <CircularProgress size={20} />
                                            :
                                            'Delete'
                                        }
                                    </Button>
                                </Grid>
                            </Grid>
                            : data.status === "approved" ? null : <Grid container item sx={{ marginTop: "20px" }}>
                                <Grid item lg={6} md={6} sm={6} xs={6} sx={{ display: "flex", justifyContent: "center" }}>
                                    <Button
                                        onClick={() => handleRequestApproalOrReject("approve")}
                                        disabled={data.status === "approved" || data.approvedCount.includes(userID) || isSubmissionLoading}
                                        sx={{
                                            backgroundColor: "#0E86D4",
                                            color: "#ffffff",
                                            ":hover": {
                                                bgcolor: "#000",
                                                color: "#fff",
                                            },
                                            textTransform: "none",
                                            padding: "5px 20px 5px 20px"
                                        }}
                                    >
                                        {submissionType === "approve" && isSubmissionLoading ?
                                            <CircularProgress size={20} />
                                            :
                                            'Approve'
                                        }
                                    </Button>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={6} sx={{ display: "flex", justifyContent: "center" }}>
                                    <Button
                                        onClick={() => handleRequestApproalOrReject("reject")}
                                        disabled={data.status === "approved" || data.rejectedApprovals.includes(userID) || isSubmissionLoading}
                                        sx={{
                                            backgroundColor: "red",
                                            color: "#ffffff",
                                            ":hover": {
                                                bgcolor: "#000",
                                                color: "#fff",
                                            },
                                            textTransform: "none",
                                            padding: "5px 25px 5px 25px"
                                        }} >
                                        {submissionType === "reject" && isSubmissionLoading ?
                                            <CircularProgress size={20} />
                                            :
                                            'Reject'
                                        }
                                    </Button>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                    : null}
            </Box>
        </Modal>
    );
}

export default RequestModal;
