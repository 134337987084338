import React, { useState } from "react";
import {
  Grid,
  Button,
  TextField,
  Typography,
  Alert,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import axios from "../axios";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { connect } from "react-redux";
import { login } from "../store/actions/authActions";
import SimpleNavBar from "../Components/SimpleNavBar"
import ChangePassword from "./ChangePassword";

let initialValues = {
  email: "",
  password: "",
};
let SignInSchema = Yup.object().shape({
  email: Yup.string().email().required("Email is required!"),
  password: Yup.string().required("Password is required!").min(3).max(20),
});
const url = process.env.REACT_APP_BE_URL;

const TitleStyles = {
  backgroundColor: "#fff",
  color: "#000",
  fontWeight: "800",
  display: "flex",
  fontSize: "2rem",
  marginTop: "5rem",
  marginBottom: "3rem",
};

const SubTitleHeaders = {
  fontWeight: "600",
  fontSize: "1rem",
};

const SubTitleHeaders2 = {
  marginTop: "1rem",
  fontWeight: "600",
  fontSize: "1rem",
  textDecoration: "none",
  color: "#0066B2",
  cursor: "pointer",
  border: "0px",
};

const fieldStyles = {
  width: { xs: 315, sm: 398 },
  marginBottom: "10px",
};

const formStyles = {
  display: "flex",
  flexDirection: "column",
};

function SignIn(props) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [openModel, setOpenModel] = React.useState(false);
  const handleCloseModel = () => setOpenModel(false);
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  const [callBack, setCallBack] = useState(false);

  useEffect(() => {
    if (alert.message !== "") {
      setOpen(true);
    }
  }, [alert]);

  const getData = async (e) => {
    try {
      setCallBack(true);
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      // eslint-disable-next-line
      const { data, status } = await axios.post(
        `${url}/user/login`,
        { email: e.email, password: e.password },
        config
      );

      const { _id } = data;
      props.login(JSON.stringify(data), _id);

      navigate(`/profile/${_id}`);
      setCallBack(false);
    } catch (error) {
      setCallBack(false);
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Invalid credentials"}`,
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);
    }
  };

  function handleClose() {
    setOpen(false);
    setAlert({
      ...alert,
      showAlert: false,
      severity: "",
      message: "",
    });
  }
  return (
    <Grid container justifyContent="center">
      <SimpleNavBar />
      <Snackbar
        open={open}
        autoHideDuration={15000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={alert.severity}
          sx={{
            fontSize: "16px",
          }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
      <Grid item style={TitleStyles}>
        Sign In
      </Grid>
      <Grid
        container
        item
        direction="column"
        alignItems="center"
      >
        <Formik
          initialValues={initialValues}
          onSubmit={getData}
          validationSchema={SignInSchema}
        >
          {({ errors, isValid, touched, dirty }) => (
            <Form style={formStyles}>
              <Typography color="primary" style={SubTitleHeaders}>
                Log in to your account
              </Typography>

              <Grid item>
                <Field
                  sx={fieldStyles}
                  name="email"
                  label="Email"
                  as={TextField}
                  variant="outlined"
                  required
                  size="small"
                  margin="dense"
                  error={Boolean(errors.email) && Boolean(touched.email)}
                  helperText={Boolean(touched.email) && errors.email}
                ></Field>
              </Grid>

              <Grid item>
                <Field
                  sx={fieldStyles}
                  name="password"
                  label="Password"
                  as={TextField}
                  variant="outlined"
                  type="password"
                  required
                  size="small"
                  margin="dense"
                  error={Boolean(errors.password) && Boolean(touched.password)}
                  helperText={Boolean(touched.password) && errors.password}
                ></Field>
              </Grid>

              <Grid item>
                <Button
                  sx={{
                    borderRadius: "8px",
                    fontSize: "1rem",
                    fontWeight: "700",
                    height: "2.5rem",
                    marginTop: "1rem",
                    marginBottom: "1rem",
                  }}
                  color="primary"
                  fullWidth
                  variant="contained"
                  disabled={!dirty || !isValid || callBack}
                  type="submit"
                  size="large"
                >
                  {callBack ?
                    <CircularProgress size={20} />
                    :
                    'Sign In'
                  }

                </Button>
                <ChangePassword open={openModel} onClose={handleCloseModel} />
                <Typography
                  style={SubTitleHeaders2}
                  color="primary"
                  onClick={() => setOpenModel(true)}
                >
                  Forgot password?
                </Typography>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
}

export default connect(null, { login })(SignIn);
