import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  TextField,
  Typography,
  FormLabel,
  FormControl,
  Alert,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import axios from "../axios";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import SignupConfirmation from "./SignupConfirmation";
import SimpleNavBar from "../Components/SimpleNavBar"
import { useSearchParams } from "react-router-dom";

const url = process.env.REACT_APP_BE_URL;

let SignUpSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("First Name is required!")
    .min(3, "Too short!")
    .max(20, "Too long!"),
  lastName: Yup.string()
    .required("Last Name is required!")
    .min(3, "Too short")
    .max(20, "Too long"),
  email: Yup.string().email().required("Email is required!"),
  password: Yup.string().required("Password is required!").min(3).max(20),
  confirmPassword: Yup.string()
    .required("Confirm Password is required!")
    .oneOf([Yup.ref("password"), null], "Passwords must match!"),
});

const TitleStyles = {
  backgroundColor: "#fff",
  color: "#000",
  fontWeight: "800",
  display: "flex",
  fontSize: "2rem",
  marginTop: "3.4rem",
  marginBottom: "1.4rem",
};

const SubTitleHeaders = {
  marginTop: "5px",
  fontWeight: "600",
  fontSize: "1rem",
};

const fieldStyles = {
  width: { xs: 315, sm: 398 },
  marginBottom: "10px",
};

const formStyles = {
  display: "flex",
  flexDirection: "column",
};

function Signup() {
  const [value, setValue] = React.useState("Creating projects");

  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const email = searchParams.get("email");

  // eslint-disable-next-line
  const [initialValues, setInitialValues] = React.useState({
    firstName: "",
    lastName: "",
    email: email ? email : "",
    password: ""
  });
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  const [open, setOpen] = useState(false);
  const [callBack, setCallBack] = useState(false);
  const [openModel, setOpenModel] = React.useState(false);
  const handleCloseModel = () => setOpenModel(false);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  function handleClose() {
    setOpen(false);
    setAlert({
      ...alert,
      showAlert: false,
      severity: "",
      message: "",
    });
  }

  useEffect(() => {
    if (alert.message !== "") {
      setOpen(true);
    }
  }, [alert]);

  const handleSubmit = async (e) => {
    try {
      setCallBack(true);
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      // eslint-disable-next-line
      const { data } = await axios.post(
        `${url}/user`,
        {
          firstName: e.firstName,
          lastName: e.lastName,
          email: e.email,
          password: e.password,
          interests: value,
          profilePic:
            "",
        },
        config
      );
      setOpenModel(true);
      setCallBack(false);
    } catch (error) {
      setCallBack(false);
      if (error?.response?.data === "Email Already Exists!") {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Email Already Exists!",
        });
      } else if (
        error?.response?.data ===
        "You haven't received an invitation for your email"
      ) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "You haven't received an invitation for your email",
        });
      } else if (error?.response?.data === "email must be a valid email") {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Email must be a valid email",
        });
      } else if (
        error?.response?.data ===
        "password length must be at least 6 characters long"
      ) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Password length must be at least 6 characters long",
        });
      } else if (error?.response?.data === "") {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "The user created succesfully!",
        });
      } else if (
        error?.response?.data === "Please verify your email invitation"
      ) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Please verify your email invitation",
        });
      } else if (
        error?.response?.data === "Please verify your sign Up verification"
      ) {
        setOpenModel(true);
      }
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);
    }
  };

  return (
    <Grid container justifyContent="center">
      <SimpleNavBar />
      <Snackbar
        open={open}
        autoHideDuration={15000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={alert.severity}
          sx={{
            fontSize: "16px",
          }}
        >
          {alert.message}
        </Alert>
      </Snackbar>

      <Grid item style={TitleStyles}>
        Sign up
      </Grid>
      <Grid container item direction="column" alignItems="center">
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={SignUpSchema}
        >
          {({ errors, isValid, touched, dirty }) => (
            <Form style={formStyles}>
              <Typography color="primary" style={SubTitleHeaders}>
                Primary Details
              </Typography>
              <Grid item>
                <Field
                  sx={fieldStyles}
                  name="firstName"
                  label="First Name"
                  as={TextField}
                  variant="outlined"
                  required
                  size="small"
                  margin="dense"
                  error={
                    Boolean(errors.firstName) && Boolean(touched.firstName)
                  }
                  helperText={Boolean(touched.firstName) && errors.firstName}
                ></Field>
              </Grid>
              <Grid item>
                <Field
                  sx={fieldStyles}
                  name="lastName"
                  label="Last Name"
                  as={TextField}
                  variant="outlined"
                  required
                  size="small"
                  margin="dense"
                  error={Boolean(errors.lastName) && Boolean(touched.lastName)}
                  helperText={Boolean(touched.lastName) && errors.lastName}
                ></Field>
              </Grid>

              <Grid item>
                <Field
                  sx={fieldStyles}
                  name="email"
                  label="Email"
                  as={TextField}
                  variant="outlined"
                  required
                  size="small"
                  margin="dense"
                  disabled
                  error={Boolean(errors.email) && Boolean(touched.email)}
                  helperText={Boolean(touched.email) && errors.email}
                ></Field>
              </Grid>

              <Grid item>
                <Field
                  sx={fieldStyles}
                  name="password"
                  label="Password"
                  as={TextField}
                  variant="outlined"
                  type="password"
                  required
                  size="small"
                  margin="dense"
                  error={Boolean(errors.password) && Boolean(touched.password)}
                  helperText={Boolean(touched.password) && errors.password}
                ></Field>
              </Grid>
              <Grid item>
                <Field
                  sx={fieldStyles}
                  name="confirmPassword"
                  label="Confirm Password"
                  as={TextField}
                  variant="outlined"
                  type="password"
                  required
                  size="small"
                  margin="dense"
                  error={
                    Boolean(errors.confirmPassword) &&
                    Boolean(touched.confirmPassword)
                  }
                  helperText={
                    Boolean(touched.confirmPassword) && errors.confirmPassword
                  }
                ></Field>
              </Grid>

              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  <Typography
                    color="primary"
                    style={SubTitleHeaders}
                    marginBottom="5px"
                  >
                    Interested In
                  </Typography>
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={value}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    sx={{ height: "2rem" }}
                    value="Creating projects"
                    control={<Radio />}
                    label="Creating Projects"
                  />
                  <FormControlLabel
                    sx={{ height: "2rem" }}
                    value="Collaborating on projects"
                    control={<Radio />}
                    label="Collaborating on Projects"
                  />
                  <FormControlLabel
                    sx={{ height: "2rem" }}
                    value="Both"
                    control={<Radio />}
                    label="Both"
                  />
                </RadioGroup>
              </FormControl>
              <SignupConfirmation open={openModel} onClose={handleCloseModel} />
              <Grid item>
                <Button
                  sx={{
                    borderRadius: "8px",
                    fontSize: "1rem",
                    fontWeight: "700",
                    height: "2.5rem",
                    marginTop: "1rem",
                  }}
                  color="primary"
                  fullWidth
                  variant="contained"
                  disabled={!dirty || !isValid || callBack}
                  type="submit"
                  size="large"
                >
                  {callBack ?
                    <CircularProgress size={20} />
                    :
                    'Sign up'
                  }
                </Button>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
}

export default Signup;
