import React, { useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Avatar,
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CreateIcon from "@mui/icons-material/Create";
import { useState } from "react";
import axios from "../axios";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Chip from "@mui/material/Chip";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import { useParams, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "../styles/styles.css";
import { ImageConfig } from "../Components/config/ImageConfig";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import CheckIcon from "@mui/icons-material/Check";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Navbar from "../Components/Navbar";
import Sidebar from "../Components/Sidebar";

import PublishModal from "./PublishModal";
import EditProjectDetailsModal1 from "./EditProjectDetailsModal1";
import EditProjectDetailsModal2 from "./EditProjectDetailsModal2";
import DuplicateJobModal from "./DuplicateJobModal"
import EqutyPageInviteModalInCommunityEdition from "./EqutyPageInviteModalInCommunityEdition";

const url = process.env.REACT_APP_BE_URL;

const SubTitleHeaders = {
  fontWeight: "700",
  fontSize: "1rem",
  display: "flex",
  alignItems: "center",
};

const buttonStyles = {
  borderRadius: "8px",
  textTransform: "none",
  width: "100%",
};

const ProjectDetails = () => {
  // open publish model
  const navigate = useNavigate();
  const [openPublishmodel, setPublishmodel] = useState(false);

  //Edit Modals
  const [openModelEdit1, setOpenModelEdit1] = React.useState(false);
  const [openModelEdit2, setOpenModelEdit2] = React.useState(false);
  const handleCloseModelEdit1 = () => {
    setOpenModelEdit1(false);
  };
  const handleCloseModelEdit2 = () => {
    setOpenModelEdit2(false);
  };
  const { projId } = useParams();
  const [project, setProject] = useState(null);
  const [jobs, setJobs] = useState(null);
  const [adminUsers, setAdminUsers] = useState(null);
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [initialValues1, setInitialValues1] = useState();
  const [loading, setLoading] = useState(true);
  const [openInvitemodel, setInvitemodel] = useState(false);

  // eslint-disable-next-line
  const [openSnackbar, setOpenSnackbar] = useState(true);
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  const [openModel, setOpenModel] = useState(false);
  const [duplicateJobDetails, setDuplicateJobDetails] = useState(null);

  const handleCloseModel = () => {
    setOpenModel(false);
  }

  function handleClose() {
    setOpenSnackbar(false);
    setAlert({
      ...alert,
      showAlert: false,
      severity: "",
      message: "",
    });
  }

  const userID = JSON.parse(localStorage.getItem("userInfo"))._id;
  const adminCheck = project?.admins?.filter((id) => {
    return userID === id;
  });

  const userCheck = project?.users?.filter((id) => {
    return userID === id
  });

  const getData = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };
    try {
      const { data, status } = await axios.get(
        `${url}/project/${projId}`,
        config
      );
      if (status === 200) {
        setProject(data);
      }
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed"}`,
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);
    }
  };

  let jobArr = [];
  const getJobs = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };
    try {
      await project.jobs?.forEach(async (jobID, index) => {
        const { data, status } = await axios.get(`${url}/job/${jobID}`, config);
        if (status === 200) {
          jobArr.push(data);
          if (userCheck?.length > 0) {
            if (project.jobs.length === jobArr.length) return setJobs(jobArr);
          } else {
            if (project.jobs.length === jobArr.length) return setJobs(jobArr.filter((item) => item.status !== "inactive"));
          }
        }
      });
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed"}`,
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);
    }
  };

  const deleteDocuments = async (id) => {
    let newList = project.iPSensitiveDetails.filter((item) => item.public_id !== id);

    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };

    try {
      let payLoad = {
        iPSensitiveDetails: newList,
      };

      // eslint-disable-next-line
      const { status, data } = await axios.put(
        `${url}/project/${project._id}`,
        payLoad,
        config
      );
      getData();

      if (status === 200) {
        setAlert({
          showAlert: true,
          severity: "success",
          message: `${data?.message ? data?.message : data ? data : "Document is deleted successfully!"}`
        });

        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "success",
            message: "",
          });
        }, 16000);
      }
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed"}`,
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);
    }
  };

  useEffect(() => {
    getData();

    // eslint-disable-next-line
  }, []);

  const getProjectAdmins = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };
    try {
      const res = await axios.get(`${url}/project/adminUsers/${projId}`, config)
      if (res.status === 200) {
        setAdminUsers(res.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getProjectAdmins()
  }, [])

  useEffect(() => {
    if (project) {
      getJobs();
      if (project.jobs.length === 0) {
        setLoading(false);
      }
      setInitialValues1({
        projectName: project ? project.name : "",
        industry: project ? project.industry : "",
        description: project ? project.description : "",
        funding: project ? project.funding : "",
      });
    }

    // eslint-disable-next-line
  }, [project]);

  useEffect(() => {
    if (jobs) {
      setLoading(false);
    }
  }, [jobs]);


  const data = {
    projectID: "",
  };

  const createJob = (id) => {
    data.projectID = id;

    navigate("/createjob1", { state: data });
  };

  const handleOpenModel = (job) => {
    setDuplicateJobDetails(process.env.REACT_APP_COMMUNITY_EDITION === "false" ? { description: job?.description, jobType: job?.jobType, projectID: job.projectID?._id, qualifications: job.qualifications, requirements: job.requirements, skills: job.skills, status: 'inactive', timeAvailability: job.timeAvailability, units: job.units } : { description: job?.description, jobType: job?.jobType, projectID: job.projectID?._id, qualifications: job.qualifications, requirements: job.requirements, skills: job.skills, status: 'inactive', timeAvailability: job.timeAvailability })
    setOpenModel(true);
  };


  return (
    <>
      <Snackbar
        sx={{ mt: "8vh" }}
        open={alert.showAlert}
        autoHideDuration={15000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={alert.severity}
          sx={{
            fontSize: "16px",
          }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
      <Navbar />

      <Grid container lg={12}>
        <Grid container item lg={1} md={1.5} sm={1.8} xs={1}>
          <Sidebar />
        </Grid>

        <Grid container item
          marginTop={10}
          lg={10.5}
          md={9.8}
          sm={9.6}
          xs={10}>
          <PublishModal
            projId={projId}
            openPublishmodel={openPublishmodel}
            setPublishmodel={setPublishmodel}
            getData={getData}
          />

          {!loading ? (
            <Grid
              container
              alignItems="center"
              justifyContent="center"
            >
              <Grid item container
                alignItems="center"
                justifyContent="center"

                lg={12}
                md={12}
                sm={12}
                xs={12}

                marginTop={{
                  lg: 3,
                  md: 3,
                  sm: 3,
                  xs: 3,
                }}

                marginBottom={{
                  lg: 3,
                  md: 0,
                  sm: 0,
                  xs: 0,
                }}

                paddingLeft={{
                  lg: "1rem",
                  md: "2rem",
                  sm: "4rem",
                  xs: "1rem",
                }}
                paddingRight={{
                  lg: "1rem",
                  md: "2rem",
                  sm: "4rem",
                  xs: "1rem",
                }}
              >
                <Grid item
                  lg={9}
                  md={12}
                  sm={12}
                  xs={12}

                  marginBottom={{
                    lg: 0,
                    md: 1,
                    sm: 0,
                    xs: 0,
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: "900",
                      textAlign: {
                        lg: "left",
                        md: "center",
                        sm: "center",
                        xs: "center",
                      },
                      fontSize: {
                        lg: "2rem",
                        md: "1.5rem",
                        sm: "1.5rem",
                        xs: "1.5rem",
                      },
                      wordWrap: "break-word"
                    }}
                  >
                    {project.name}
                    {userCheck?.length > 0 ? (
                      <Chip
                        sx={{
                          marginLeft: "1rem",
                          padding: "15px 10px 15px 10px",
                          fontWeight: 400,
                        }}
                        size="small"
                        label={project.status}
                        color={
                          project.status === "pending" ? "warning" : "success"
                        }
                        icon={
                          project.status === "pending" ? (
                            <PriorityHighIcon />
                          ) : (
                            <CheckIcon />
                          )
                        }
                      />
                    ) : null}
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent={{
                    lg: "flex-end",
                    md: "center",
                    sm: "center",
                    xs: "center",
                  }}
                  alignItems="center"
                  lg={3}
                  md={7}
                  sm={12}
                  xs={12}
                  sx={{
                    mt: {
                      lg: "0rem",
                      md: "0rem",
                      sm: "1rem",
                      xs: "2rem",
                    },
                    mb: {
                      lg: "0rem",
                      md: "2rem",
                      sm: "1rem",
                      xs: "1rem",
                    },
                  }}
                >
                  {adminCheck?.length > 0 && project.status === "pending" ? (
                    <Grid
                      item
                      lg={"auto"}
                      md={12}
                      sm={12}
                      xs={12}
                      sx={{
                        textAlign: "center",
                        mt: {
                          lg: "0",
                          md: "0",
                          sm: "0",
                          xs: "0.5rem",
                        },
                      }}
                    >
                      {" "}
                      <Button
                        color="success"
                        variant="contained"
                        style={buttonStyles}
                        onClick={() => setPublishmodel(true)}
                      >
                        Publish
                      </Button>
                    </Grid>) : null}
                  {process.env.REACT_APP_COMMUNITY_EDITION === 'false' ?
                    <Grid
                      item
                      lg={"auto"}
                      md={12}
                      sm={12}
                      xs={12}
                      sx={{
                        textAlign: "center",
                        mt: {
                          lg: "0",
                          md: "0.5rem",
                          sm: "0.5rem",
                          xs: "0.5rem",
                        },

                        ml: {
                          lg: "1rem",
                          md: "0",
                          sm: "0",
                          xs: "0",
                        },
                      }}
                    >
                      {userCheck?.length > 0 ? (
                        <Link
                          to={`/equitydetails/${project._id}`}
                          style={{ textDecoration: "none", color: "initial" }}
                        >
                          <Button
                            color="primary"
                            variant="contained"
                            style={buttonStyles}

                          >
                            Equity Details
                          </Button>
                        </Link>
                      ) : null}
                    </Grid>

                    :

                    <> {adminCheck?.length > 0 && project.status === "pending" ? (
                      <Button
                        variant="contained"
                        sx={{
                          borderRadius: "8px",
                          textTransform: "none",
                          marginLeft: {
                            lg: "1rem",
                            md: "1rem",
                            sm: "1rem",
                            xs: "0.5rem",
                          },
                          marginRight: {
                            lg: "0rem",
                            md: "0rem",
                            sm: "0rem",
                            xs: "0.5rem",
                          },
                          marginTop: {
                            lg: "1rem",
                            md: "1rem",
                            sm: "1rem",
                            xs: "1rem",
                          },
                          marginBottom: {
                            lg: "1rem",
                            md: "1rem",
                            sm: "1rem",
                            xs: "1rem",
                          },
                        }}
                        onClick={() => setInvitemodel(true)}
                      >
                        Invite collaborators
                      </Button>) : ("")}
                      <EqutyPageInviteModalInCommunityEdition
                        projId={projId}
                        openPublishmodel={openInvitemodel}
                        setPublishmodel={setInvitemodel}
                        getData={getData}
                        getProjectAdmins={getProjectAdmins}
                      />

                    </>
                  }

                </Grid>
              </Grid>

              <Grid container
                lg={11.5}
                md={12}
                sm={12}
                xs={12}
                mt={2}
                justifyContent="space-between">
                {/* Private Details */}
                <EditProjectDetailsModal1
                  disableEnforceFocus
                  open={openModelEdit1}
                  onClose={handleCloseModelEdit1}
                  project={project}
                  getData={getData}
                  initialValues1={initialValues1}
                />
                <Grid
                  item
                  lg={5.8}
                  md={5.8}
                  sm={12}
                  xs={12}
                  sx={{ marginRight: { xs: "none", md: 2 }, mb: "1rem" }}
                >
                  <Box
                    border={1}
                    borderRadius="8px"
                    padding="8px 16px 16px 16px"
                    height="175px"
                  >
                    <Grid container item
                      marginLeft={2}
                      marginBottom={1}
                      xs={12}>
                      <Typography
                        color="primary"
                        style={SubTitleHeaders}
                        sx={{
                          flexGrow: 1,
                        }}
                      >
                        Project Details
                      </Typography>
                      <Stack direction="row"
                        rowspacing={2} marginRight="1rem">
                        {adminCheck?.length > 0 ? (
                          <Button
                            variant="outlined"
                            onClick={() => setOpenModelEdit1(true)}
                          >
                            <CreateIcon fontSize="small" />
                          </Button>
                        ) : null}
                      </Stack>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      marginLeft={2}
                      sx={{ wordWrap: "break-word" }} >
                      <Grid container item justifyContent="flex-start">
                        <Grid item xs={3} md={3}>
                          <List dense disablePadding disableGutters>
                            <ListItem
                              disablePadding
                              disableGutters
                              sx={{ display: "list-item" }}
                            >
                              <Typography fontSize="0.75rem">
                                Industry
                              </Typography>
                              {process.env.REACT_APP_COMMUNITY_EDITION === 'false' ?
                                   (userCheck?.length > 0 ? (
                                           <Typography fontSize="0.75rem">
                                           Funding
                                         </Typography>
                                       ) : null)
                                      :
                                <Typography fontSize="0.75rem">
                                  Funding
                                </Typography>
                                }
                            </ListItem>
                          </List>
                        </Grid>
                        <Grid
                          item
                          xs={8}
                          md={9}
                          sx={{ wordWrap: "break-word" }}
                        >
                          <List dense disablePadding disableGutters>
                            <ListItem
                              disablePadding
                              sx={{ display: "list-item" }}
                            >
                              <Typography fontSize="0.75rem">
                                - {project.industry}
                              </Typography>
                              {process.env.REACT_APP_COMMUNITY_EDITION === 'false' ?
                              (userCheck?.length > 0 ? (
                                <Typography fontSize="0.75rem">
                                  - {project.funding}
                                </Typography>) : null)
                              :
                                <Typography fontSize="0.75rem">
                                  - {project.funding}
                                </Typography>
                                }
                            </ListItem>
                          </List>
                        </Grid>
                      </Grid>

                      <Typography
                        style={{
                          overflow: "auto",
                          height: "4.5rem",
                          marginTop: "0.5rem",
                          fontSize: "0.75rem",
                        }}
                      >
                        {project.description}
                      </Typography>
                    </Grid>
                  </Box>
                </Grid>

                <EditProjectDetailsModal2
                  disableEnforceFocus
                  open={openModelEdit2}
                  onClose={handleCloseModelEdit2}
                  project={project}
                  getData={getData}
                />
                {/* Documents */}

                <Grid item xs={12} md={5.8} >
                  {userCheck?.length > 0 || process.env.REACT_APP_COMMUNITY_EDITION === 'true' ? (
                    <Box
                      border={1}
                      borderRadius="8px"
                      padding="8px 16px 16px 16px"
                      height="175px" >
                      <Grid container item
                        marginLeft={2}
                        marginBottom={1}
                        xs={12}>
                        <Typography
                          color="primary"
                          style={SubTitleHeaders}
                          sx={{
                            flexGrow: 1,
                          }} >
                          Documents
                        </Typography>
                        <Stack direction="row" rowspacing={2} marginRight="1rem">
                          {adminCheck?.length > 0 ? (
                            <Button
                              variant="outlined"
                              onClick={() => setOpenModelEdit2(true)}
                            >
                              <AddIcon fontSize="small" />
                            </Button>
                          ) : null}
                        </Stack>
                      </Grid>
                      <Grid container item xs={12} sx={{
                        minHeight: "120px",
                        maxHeight: "120px",
                        overflowX: "hidden",
                        overflowY: "auto",
                      }}>
                        {project?.iPSensitiveDetails.map((item, index) => (
                          <Grid
                            key={item.public_id}
                            item
                            xs={12}
                          >
                            <List disablePadding >
                              <ListItem disablePadding disableGutters>
                                <ListItemIcon>
                                  <img
                                    style={{
                                      width: "60px",
                                      height: "60px"
                                    }}
                                    src={
                                      ImageConfig["default"]
                                    }
                                    alt=""
                                  />

                                </ListItemIcon>
                                <ListItemText sx={{
                                  width: { xs: "200px" },
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                                  primary={
                                    <a
                                      rel="noopener noreferrer"
                                      target="_blank"
                                      href={item.url}
                                      style={{
                                        fontSize: "0.75rem",
                                        textDecoration: "none",
                                        "&:hover": {
                                          backgroundColor: "#fff",
                                          color: "#3c52b2",
                                        },
                                      }}
                                    >
                                      {item.url}
                                    </a>
                                  } />
                                {project.iPSensitiveDetails.length !== 1 && adminCheck?.length > 0 ? (<Button
                                  onClick={() => deleteDocuments(item.public_id)}
                                  sx={{ mb: "15px" }}
                                  disabled={project.iPSensitiveDetails.length === 1}
                                >
                                  <DeleteIcon sx={{ color: "#C80815" }} />
                                </Button>) : null}
                              </ListItem>
                            </List>

                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  ) : null}
                </Grid>

                {/* Founding Members */}
                {adminUsers?.length > 0 ? (<Grid
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  container
                  item
                  mt={2}

                >
                  <Box
                    border={1}
                    borderRadius="8px"
                    padding="8px 16px 16px 16px"
                    width="100%"

                  >
                    <Grid container item
                      marginLeft={2}
                      marginBottom={1}
                      xs={12}>

                      <Typography
                        color="primary"
                        style={SubTitleHeaders}
                        sx={{
                          flexGrow: 1,
                        }} >
                        Founders/Co-Founders
                      </Typography>
                    </Grid>
                    {adminUsers?.length > 0 ? (<Grid
                      container
                      item
                      direction={{
                        lg: "row",
                        md: "row",
                        sm: "row",
                        xs: "row",
                      }}
                      alignItems="center"
                      justifyContent="space-evenly"
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      sx={{
                        minHeight: "auto",
                        maxHeight: { xs: "120px", sm: "200px", md: "200px", lg: "200px" },
                        overflowX: "hidden",
                        overflowY: "auto",

                      }}


                    >
                      {adminUsers?.map((item) => {
                        return (
                          <Grid
                            key={item._id}
                            item
                            lg={3}
                            md={6}
                            sm={6}
                            xs={12}
                          >
                            <List dense>
                              <ListItem disablePadding disableGutters>
                                <ListItemButton
                                  onClick={() =>
                                    navigate(`/profile/${item._id}`)
                                  }
                                >
                                  <ListItemIcon
                                    sx={{ justifyContent: "center" }}
                                  >
                                    <Avatar
                                      src={item.profilePic.url}
                                      sx={{
                                        height: {
                                          lg: 60,
                                          md: 50,
                                          sm: 50,
                                          xs: 40,
                                        },
                                        width: {
                                          lg: 60,
                                          md: 50,
                                          sm: 50,
                                          xs: 40,
                                        },
                                        margin: "0 0.5rem",
                                      }}
                                    >

                                    </Avatar>
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={`${item.firstName} ${item.lastName}`}
                                  />
                                </ListItemButton>
                              </ListItem>
                            </List>
                          </Grid>
                        );
                      })}
                    </Grid>) : (<Grid
                      container
                      item
                      direction={{
                        lg: "row",
                        md: "row",
                        sm: "row",
                        xs: "row",
                      }}
                      alignItems="center"
                      justifyContent="space-evenly"
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      sx={{
                        minHeight: "auto",
                        maxHeight: { xs: "120px", sm: "200px", md: "200px", lg: "200px" },
                        overflowX: "hidden",
                        overflowY: "auto",

                      }}


                    >
                      <Grid
                        item
                        lg={3}
                        md={6}
                        sm={6}
                        xs={12}
                      >
                        <List dense>
                          <ListItem disablePadding disableGutters>
                            <ListItemButton
                            >
                              <ListItemIcon
                                sx={{ justifyContent: "center" }}
                              >
                                <Skeleton
                                  variant="circular"
                                  sx={{
                                    height: {
                                      lg: 60,
                                      md: 50,
                                      sm: 50,
                                      xs: 40,
                                    },
                                    width: {
                                      lg: 60,
                                      md: 50,
                                      sm: 50,
                                      xs: 40,
                                    },
                                    margin: "0 0.5rem",
                                  }}
                                >

                                </Skeleton>
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <>
                                    <Skeleton variant="rectangular" />
                                  </>
                                }
                              />
                            </ListItemButton>
                          </ListItem>
                        </List>
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        md={6}
                        sm={6}
                        xs={12}
                      >
                        <List dense>
                          <ListItem disablePadding disableGutters>
                            <ListItemButton
                            >
                              <ListItemIcon
                                sx={{ justifyContent: "center" }}
                              >
                                <Skeleton
                                  variant="circular"
                                  sx={{
                                    height: {
                                      lg: 60,
                                      md: 50,
                                      sm: 50,
                                      xs: 40,
                                    },
                                    width: {
                                      lg: 60,
                                      md: 50,
                                      sm: 50,
                                      xs: 40,
                                    },
                                    margin: "0 0.5rem",
                                  }}
                                >

                                </Skeleton>
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <>
                                    <Skeleton variant="rectangular" />
                                  </>
                                }
                              />
                            </ListItemButton>
                          </ListItem>
                        </List>
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        md={6}
                        sm={6}
                        xs={12}
                      >
                        <List dense>
                          <ListItem disablePadding disableGutters>
                            <ListItemButton
                            >
                              <ListItemIcon
                                sx={{ justifyContent: "center" }}
                              >
                                <Skeleton
                                  variant="circular"
                                  sx={{
                                    height: {
                                      lg: 60,
                                      md: 50,
                                      sm: 50,
                                      xs: 40,
                                    },
                                    width: {
                                      lg: 60,
                                      md: 50,
                                      sm: 50,
                                      xs: 40,
                                    },
                                    margin: "0 0.5rem",
                                  }}
                                >

                                </Skeleton>
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <>
                                    <Skeleton variant="rectangular" />
                                  </>
                                }
                              />
                            </ListItemButton>
                          </ListItem>
                        </List>
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        md={6}
                        sm={6}
                        xs={12}
                      >
                        <List dense>
                          <ListItem disablePadding disableGutters>
                            <ListItemButton
                            >
                              <ListItemIcon
                                sx={{ justifyContent: "center" }}
                              >
                                <Skeleton
                                  variant="circular"
                                  sx={{
                                    height: {
                                      lg: 60,
                                      md: 50,
                                      sm: 50,
                                      xs: 40,
                                    },
                                    width: {
                                      lg: 60,
                                      md: 50,
                                      sm: 50,
                                      xs: 40,
                                    },
                                    margin: "0 0.5rem",
                                  }}
                                >

                                </Skeleton>
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <>
                                    <Skeleton variant="rectangular" />
                                  </>
                                }
                              />
                            </ListItemButton>
                          </ListItem>
                        </List>
                      </Grid>
                    </Grid>)}
                  </Box>
                </Grid>) : null}
              </Grid>

              {/* job */}

              <Grid item container
                xs={12}
                md={12}
                alignItems="center"
                justifyContent="center"
                marginTop={3}
                paddingLeft={{
                  lg: "1rem",
                  md: "0rem",
                  sm: "0rem",
                  xs: "0rem",
                }}
                paddingRight={{
                  lg: "1rem",
                  md: "0rem",
                  sm: "0rem",
                  xs: "0rem",
                }}
              >
                <Grid item
                  xs={10}
                  sm={5}
                  md={5}
                  alignItems="center">
                  <Typography
                    marginBottom={1}
                    sx={{ fontSize: { xs: "1.2rem", sm: "1.5rem" } }}
                    fontWeight="500"
                  // marginBottom="0.6rem"
                  >
                    {jobs
                      ? "Jobs for this Project"
                      : "No Jobs for this Project"}
                  </Typography>
                </Grid>

                <Grid
                  item
                  container
                  justifyContent="flex-end"
                  xs={7}
                  sx={{ display: { xs: "none", sm: "flex" } }}
                >
                  {adminCheck?.length > 0 ? (
                    <Grid item marginBottom="0.6rem">
                      <Button
                        color="primary"
                        variant="contained"
                        style={buttonStyles}
                        onClick={() => {
                          createJob(project._id);
                        }}
                      >
                        Create a Job
                      </Button>
                    </Grid>
                  ) : null}
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="flex-end"
                  xs={2}
                  sx={{ display: { xs: "flex", sm: "none" } }}
                >
                  {adminCheck?.length > 0 ? (
                    <Grid item marginBottom="0.6rem">
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => {
                          createJob(project._id);
                        }}
                      >
                        <AddIcon />
                      </Button>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>

              <Grid xs={12} md={12} marginBottom={2}
                paddingLeft={{
                  lg: "1rem",
                  md: "0rem",
                  sm: "0rem",
                  xs: "0rem",
                }}
                paddingRight={{
                  lg: "1rem",
                  md: "0rem",
                  sm: "0rem",
                  xs: "0rem",
                }}>
                {jobs?.map((job) => {
                  return (
                    <Accordion
                      expanded={expanded === job._id}
                      onChange={handleChange(job._id)}
                      sx={{ border: "1px" }}
                      key={job._id}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        sx={{
                          border: "1px",
                          borderRadius: "8px",
                          background: "#E5E5E5",
                        }}
                      >
                        <Typography
                          sx={{
                            width: { xs: "55%", sm: "26%" },
                            flexShrink: 0,
                            display: "flex",
                            justifyContent: "flex-Start",
                            alignItems: "center",
                            marginRight: "10px",
                            fontSize: { xs: "0.8rem", md: "1rem" },
                          }}
                          textAlign="center"
                          fontWeight="600"
                        >
                          {job.title}
                        </Typography>

                        <Grid
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                          }}
                        >
                          {userCheck?.length > 0 ? (
                            <Chip
                              sx={{
                                marginLeft: "1rem",
                                padding: { xs: "5px", md: "15px 10px 15px 10px" },
                                fontWeight: 400,
                                fontSize: "0.8rem",
                              }}
                              size="small"
                              label={job.status}
                              color={
                                job.status === "inactive" ? "warning" : "success"
                              }
                              icon={
                                job.status === "inactive" ? (
                                  <PriorityHighIcon />
                                ) : (
                                  <CheckIcon />
                                )
                              }
                            />) : null}
                        </Grid>
                        <Grid
                          container
                          sx={{
                            display: { xs: "none", sm: "flex" },
                            justifyContent: "flex-end",
                            alignItems: "center",
                          }}
                        >{adminCheck?.length > 0 ?
                          <ContentCopyIcon
                            onClick={() => handleOpenModel(job)}
                            sx={{
                              background: "#0066B2",
                              color: "#fff",
                              margin: "0 10px",
                              fontSize: 36,
                              padding: "4px",
                              borderRadius: "8px",
                            }}
                          /> : null}
                          <ReadMoreIcon
                            onClick={() => navigate(`/jobDetails/${job._id}`)}
                            sx={{
                              background: "#0066B2",
                              color: "#fff",
                              margin: "0 10px",
                              fontSize: 36,
                              padding: "4px",
                              borderRadius: "8px",
                            }}
                          />
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          // background: "#E5E5E5",
                          borderRadius: "8px",
                          padding: "8px 16px",
                        }}
                      >
                        <Grid item xs={12}>
                          <Grid container item justifyContent="flex-start">
                            <Grid item xs={4} md={3}>
                              <List dense disablePadding disableGutters>
                                <ListItem
                                  disablePadding
                                  disableGutters
                                  sx={{ display: "list-item" }}
                                >
                                  <Typography
                                    sx={{ lineHeight: "1.5rem" }}
                                    fontSize="0.75rem"
                                  >
                                    Job Type
                                  </Typography>
                                  <Typography
                                    fontSize="0.75rem"
                                    sx={{ lineHeight: "1.5rem" }}
                                  >
                                    Job Title
                                  </Typography>
                                  <Typography
                                    fontSize="0.75rem"
                                    sx={{ lineHeight: "1.5rem" }}
                                  >
                                    Time Availabitlity
                                  </Typography>
                                  {process.env.REACT_APP_COMMUNITY_EDITION === "false" &&
                                    <Typography
                                      fontSize="0.75rem"
                                      sx={{ lineHeight: "1.5rem" }}
                                    >
                                      Units
                                    </Typography>
                                  }
                                </ListItem>
                              </List>
                            </Grid>
                            <Grid item xs={6} md={4}>
                              <List dense disablePadding disableGutters>
                                <ListItem
                                  disablePadding
                                  disableGutters
                                  sx={{ display: "list-item" }}
                                >
                                  <Typography
                                    fontSize="0.75rem"
                                    sx={{ lineHeight: "1.5rem" }}
                                  >
                                    - {job.jobType}
                                  </Typography>
                                  <Typography
                                    fontSize="0.75rem"
                                    sx={{ lineHeight: "1.5rem" }}
                                  >
                                    - {job.title}
                                  </Typography>
                                  <Typography
                                    fontSize="0.75rem"
                                    sx={{ lineHeight: "1.5rem" }}
                                  >
                                    - {job.timeAvailability}
                                  </Typography>
                                  {process.env.REACT_APP_COMMUNITY_EDITION === "false" &&
                                    <Typography
                                      fontSize="0.75rem"
                                      sx={{ lineHeight: "1.5rem" }}
                                    >
                                      - {job.units} (
                                      {(
                                        (parseInt(job.units) /
                                          parseInt(job?.projectID?.totalUnits)) *
                                        100
                                      ).toFixed(2)}
                                      %)
                                    </Typography>
                                  }
                                </ListItem>
                              </List>
                            </Grid>
                            <Grid
                              item
                              container
                              justifyContent="flex-end"
                              xs={2}
                              md={5}
                              sx={{ display: { xs: "flex", sm: "none" } }}
                            >
                              <Grid item>
                                {adminCheck?.length > 0 ?
                                  <ContentCopyIcon
                                    onClick={() =>
                                      handleOpenModel(job)
                                    }
                                    sx={{
                                      background: "#0066B2",
                                      color: "#fff",
                                      margin: "0 10px",
                                      fontSize: 30,
                                      padding: "4px",
                                      borderRadius: "6px",
                                    }}
                                  /> : null}
                                <ReadMoreIcon
                                  onClick={() =>
                                    navigate(`/jobDetails/${job._id}`)
                                  }
                                  sx={{
                                    background: "#0066B2",
                                    color: "#fff",
                                    margin: "0 10px",
                                    fontSize: 30,
                                    padding: "4px",
                                    borderRadius: "6px",
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </Grid>
              <DuplicateJobModal open={openModel} onClose={handleCloseModel} duplicateJobDetails={duplicateJobDetails} refreshPage={getData} setAlert={setAlert} />
            </Grid>
          ) : (
            <Grid
              item
              container
              alignItems="center"
              justifyContent="center"
              style={{ height: "60vh" }}
            >
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ProjectDetails;
