import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  TextField,
  Modal,
  Box,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import axiosInstance from "../../axios";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const url = process.env.REACT_APP_BE_URL;

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.gray,
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}));

const TitleStyles = {
  backgroundColor: "#fff",
  color: "#000",
  fontWeight: "800",
  display: "flex",
  fontSize: "2rem",
  marginTop: "1rem",
  marginBottom: "1rem",
};

const fieldStyles = {
  width: { xs: 298, sm: 398 },
  marginBottom: "10px",
};

const formStyles = {
  display: "flex",
  flexDirection: "column",
};

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 350, sm: 550 },
  bgcolor: "#fff",
  boxShadow: 30,
  p: 4,
  borderRadius: "8px",
  padding: "30px",
  border: "0px",
};

function JobQualificationsmodel({ open, onClose, data, getData }) {

  const [isSubmissionLoading, setSubmissionLoading] = useState(false);
  const [value, setValue] = useState(data?.qualifications);
  const qualifications = [
    "Teamwork",
    "Multitasking",
    "Decision-making",
    "Self-starting ability",
    "Strategizing",
  ];

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  function handleClose() {
    setAlert({
      ...alert,
      showAlert: false,
      severity: "",
      message: "",
    });
  }

  const handleSubmit = async () => {
    setSubmissionLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };

    try {
      let payLoad = {
        qualifications: value,
      };

      const res = await axiosInstance.put(`${url}/job/${data._id}`, payLoad, config);
      onClose();
      getData();

      if (res.status === 200) {
        setAlert({
          showAlert: true,
          severity: "success",
          message: `${res.data?.message ? res.data?.message : res.data ? res.data : "Qualifications updated!"}`,
        });
      }
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action failed"}`,
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);
    }
    setSubmissionLoading(false);
  };

  useEffect(() => {
    setValue(data?.qualifications);
  }, [data]);

  return (

    <>
      <Snackbar
        sx={{ mt: "8vh" }}
        open={alert.showAlert}
        autoHideDuration={15000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={alert.severity}
          sx={{
            fontSize: "16px",
          }}
        >
          {alert.message}
        </Alert>
      </Snackbar>

      <Modal open={open} onClose={onClose}>
        <Box sx={style}>
          <Grid container justifyContent="center">
            <Grid item style={TitleStyles}>
              Qualifications
            </Grid>
            <Grid container item direction="column" alignItems="center">
              <Grid style={formStyles}>
                <Autocomplete
                  multiple
                  options={qualifications}
                  defaultValue={value}
                  renderInput={(params) => (
                    <LightTooltip title="Type and press enter to add custom values" placement="top-start">
                      <TextField
                        sx={fieldStyles}
                        {...params}
                        size="small"
                        variant="outlined"
                      />
                    </LightTooltip>
                  )}
                  value={value}
                  freeSolo
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                />

                <Grid item>
                  <Button
                    sx={{
                      borderRadius: "8px",
                      fontSize: "1rem",
                      fontWeight: "700",
                      height: "2.5rem",
                      marginTop: "1rem",
                      minWidth: { xs: 298, sm: 398, },
                    }}
                    color="primary"
                    fullWidth
                    variant="contained"
                    type="submit"
                    size="large"
                    onClick={handleSubmit}
                    disabled={isSubmissionLoading}
                  >
                    {isSubmissionLoading ?
                      <CircularProgress size={20} />
                      :
                      'Submit'
                    }
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default JobQualificationsmodel;
