import React, { useState } from "react";
import {
  Grid,
  Button,
  TextField,
  Box,
  FormControl,
  MenuItem,
  Select,
  InputLabel
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import Drawer from "../Components/Drawer";
import { useDispatch, useSelector } from "react-redux";
import { getForm1Data } from "../store/actions/jobActions";

let CreateJob1Schema = Yup.object().shape({
  title: Yup.string()
    .required("Job Title is required")
    .min(3, "Too short")
    .max(20, "Too long"),
  jobType: Yup.string()
    .required("Job Type is required"),
  units: Yup.number().required("Units are  required"),
  description: Yup.string()
    .required("Description is required")
    .min(100, "Too short")
    .max(500, "Too long"),
});

let CreateJob1SchemaCommunity = Yup.object().shape({
  title: Yup.string()
    .required("Job Title is required")
    .min(3, "Too short")
    .max(20, "Too long"),
  jobType: Yup.string()
    .required("Job Type is required"),
  description: Yup.string()
    .required("Description is required")
    .min(100, "Too short")
    .max(500, "Too long"),
});

const TitleStyles = {
  backgroundColor: "#fff",
  color: "#000",
  fontWeight: "800",
  display: "flex",
  fontSize: "2rem",
  marginTop: "3rem",
  marginBottom: "3rem",
};

const fieldStyles = {
  width: { xs: 315, sm: 398 },
  marginBottom: "10px",
};

const fieldStyles2 = {
  width: { xs: 315, sm: 398 },
  marginBottom: "10px",
  height: "2.5rem",
  marginTop: "0.5rem",
};

const formStyles = {
  display: "flex",
  flexDirection: "column",
};

// const data = {
//   title: "",
//   description: "",
//   jobType: "",
//   skills: [],
//   timeAvailability: "",
//   status: "active",
//   units: "",
//   request: [""],
// };


//select formik setup
const CustomizedSelectForFormik = ({ children, form, field }) => {

  const { name, value } = field;
  const { setFieldValue } = form;

  return (
    <Select
      required
      label="job Type"
      name={name}
      value={value}
      onChange={e => {
        setFieldValue(name, e.target.value);
      }}
    >
      {children}
    </Select>
  );
};


function CreateJob1() {
  const navigate = useNavigate();
  const { state } = useLocation()
  const projID = state;

  const dispatch = useDispatch()
  const data = useSelector(state => state.job.jobDetails)

  const [timeAvailability, setTimeAvailability] = useState("part-time");

  const handleChange = (event) => {
    setTimeAvailability(event.target.value);
  };

  const [initialValues1, setInitialValues1] = useState(
    process.env.REACT_APP_COMMUNITY_EDITION === "false" ?
      {
        title: data ? data.title : "",
        jobType: data ? data.jobType : "",
        timeAvailability: data ? data.timeAvailability : "",
        units: data ? data.units : "",
        description: data ? data.description : "",
      }
      :
      {
        title: data ? data.title : "",
        jobType: data ? data.jobType : "",
        timeAvailability: data ? data.timeAvailability : "",
        description: data ? data.description : "",
      }
  );

  const handleSubmit1 = async (values, onSubmitProps) => {
    let payload = {
      title: values.title,
      jobType: values.jobType,
      timeAvailability: timeAvailability,
      description: values.description
    }

    if (process.env.REACT_APP_COMMUNITY_EDITION === "false") {
      payload.units = values.units;
    }

    dispatch(getForm1Data(payload))

    onSubmitProps.setSubmitting(false);
    onSubmitProps.resetForm();

    setInitialValues1(
      process.env.REACT_APP_COMMUNITY_EDITION === "false" ?
        {
          title: "",
          jobType: "",
          timeAvailability: "",
          units: "",
          description: "",
        }
        :
        {
          title: "",
          jobType: "",
          timeAvailability: "",
          description: "",
        }
    );

    navigate("/createjob2", {
      state: {
        id: projID
      }
    });
  };
  return (
    <>
      <Drawer />
      <Grid container justifyContent="center" sx={{ paddingTop: "40px" }}>
        <Grid item style={TitleStyles}>
          Step 1
        </Grid>

        <Grid container item direction="column" alignItems="center">
          <Formik
            initialValues={initialValues1}
            onSubmit={handleSubmit1}
            validationSchema={process.env.REACT_APP_COMMUNITY_EDITION === "false" ? CreateJob1Schema : CreateJob1SchemaCommunity}
          >
            {({ errors, isValid, touched, dirty }) => (
              <Form style={formStyles}>
                <Grid item>
                  <Field
                    sx={fieldStyles}
                    name="title"
                    label="Job Title"
                    as={TextField}
                    variant="outlined"
                    type="text"
                    required
                    size="small"
                    margin="dense"
                    error={Boolean(errors.title) && Boolean(touched.title)}
                    helperText={Boolean(touched.title) && errors.title}
                  ></Field>
                </Grid>
                <Grid item>
                  <Box sx={{ minWidth: 120, marginBottom: "0.8rem" }}>
                    <FormControl fullWidth required>
                      <InputLabel
                        id="demo-simple-select-label"
                        name="jobType"
                      >
                        job Type
                      </InputLabel>

                      <Field
                        component={CustomizedSelectForFormik}
                        name="jobType"
                        as={Select}

                        error={
                          Boolean(errors.jobType) && Boolean(touched.jobType)
                        }
                        helperText={Boolean(touched.jobType) && errors.jobType}
                      >

                        <MenuItem value="Accounting">Accounting</MenuItem>
                        <MenuItem value="Legal">Legal</MenuItem>
                        <MenuItem value="Engineering">Engineering</MenuItem>
                        <MenuItem value="Marketing">Marketing</MenuItem>
                        <MenuItem value="Sales">Sales</MenuItem>
                        <MenuItem value="Management">Management</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                      </Field>
                    </FormControl>
                  </Box>
                </Grid>

                <Grid item>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel
                        id="demo-simple-select-label"
                        sx={fieldStyles}
                      >
                        Time Availability
                      </InputLabel>

                      <Field
                        as={Select}
                        sx={fieldStyles2}
                        value={timeAvailability}
                        label="Time Availability"
                        onChange={handleChange}
                        required
                      >
                        <MenuItem value="full-time">Full Time</MenuItem>
                        <MenuItem value="part-time">Part Time</MenuItem>
                      </Field>
                    </FormControl>
                  </Box>
                </Grid>
                {process.env.REACT_APP_COMMUNITY_EDITION === "false" &&
                  <Grid item>
                    <Field
                      sx={fieldStyles}
                      name="units"
                      label="Units"
                      as={TextField}
                      variant="outlined"
                      type="number"
                      required
                      size="small"
                      margin="dense"
                      error={Boolean(errors.units) && Boolean(touched.units)}
                      helperText={Boolean(touched.units) && errors.units}
                    ></Field>
                  </Grid>
                }
                <Grid item>
                  <Field
                    sx={fieldStyles}
                    name="description"
                    label="Description (Min. 100 characters)"
                    as={TextField}
                    variant="outlined"
                    type="text"
                    required
                    size="small"
                    margin="dense"
                    multiline
                    rows={6}
                    error={
                      Boolean(errors.description) &&
                      Boolean(touched.description)
                    }
                    helperText={
                      Boolean(touched.description) && errors.description
                    }
                  ></Field>
                </Grid>

                <Grid item>
                  <Button
                    sx={{
                      borderRadius: "8px",
                      fontSize: "1rem",
                      fontWeight: "700",
                      height: "2.5rem",
                      marginTop: "1rem",
                    }}
                    color="primary"
                    fullWidth
                    variant="contained"
                    disabled={!dirty || !isValid}
                    type="submit"
                    size="large"
                  >
                    Continue
                  </Button>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </>
  );
}

export default CreateJob1;
