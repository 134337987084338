import React, { useState } from "react";
import { Grid, Button, TextField, Box, Alert, Snackbar, CircularProgress } from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useSearchParams, useNavigate } from "react-router-dom";
import SimpleNavBar from "../Components/SimpleNavBar";
import axios from "../axios";

let initialValues = {
  newPassword: "",
  confirmPassword: "",
};

let resetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string().required("Password is required!").min(3).max(20),
  confirmPassword: Yup.string()
    .required("Confirm Password is required!")
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match!"),
});

const TitleStyles = {
  backgroundColor: "#fff",
  color: "#000",
  fontWeight: "800",
  display: "flex",
  fontSize: "2rem",
  marginBottom: "1rem",

  width: 315,
};
const TitleStyles2 = {
  backgroundColor: "#fff",
  color: "#0066B2",
  fontWeight: "600",
  display: "flex",
  fontSize: "1rem",
  marginTop: "0.5rem",
  marginBottom: "1.5rem",
  width: 315,
};

const fieldStyles = {
  width: { xs: 315, sm: 398 },
  marginBottom: "10px",
};

const formStyles = {
  display: "flex",
  flexDirection: "column",
};

const style = {
  position: "absolute",
  top: "35%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 315,
  bgcolor: "#fff",
  borderRadius: "8px",
  border: "0px",
};

function ResetPassword() {

  const [callBack, setCallBack] = useState(false);
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");
  const navigate = useNavigate();

  function handleClose() {
    setAlert({
      ...alert,
      showAlert: false,
      severity: "",
      message: "",
    });
  }

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const resetPassword = async (values) => {

    try {
      setCallBack(true);
      const { data, status } = await axios.put(`user/resetPassword/${id}`, { password: values.newPassword });
      if (status === 200) {
        setAlert({
          showAlert: true,
          severity: "success",
          message: data,
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "success",
            message: "",
          });
        }, 16000);
        navigate('/signin');
      }
      setCallBack(false);
    } catch (error) {
      setCallBack(false);
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed"}`,
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);
    }
  }

  return (
    <>
      <SimpleNavBar />
      <Snackbar
        sx={{ mt: "8vh" }}
        open={alert.showAlert}
        autoHideDuration={15000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={alert.severity}
          sx={{
            fontSize: "16px",
          }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
      <Box sx={style}>
        <Grid container justifyContent="center">
          <Grid container item direction="column" alignItems="center">
            <Grid
              container
              item
              direction="column"
              alignItems="flex-start"
            >
              <Grid item style={TitleStyles}>
                Reset password
              </Grid>
              <Grid item style={TitleStyles2}>
                Create a new strong password with <br />minimun 6 charactors long
              </Grid>
              <Formik
                initialValues={initialValues}
                onSubmit={resetPassword}
                validationSchema={resetPasswordSchema}
              >
                {({ errors, isValid, touched, dirty }) => (
                  <Form style={formStyles}>
                    <Grid item>
                      <Field
                        sx={fieldStyles}
                        name="newPassword"
                        label="New Password"
                        as={TextField}
                        variant="outlined"
                        required
                        size="small"
                        margin="dense"
                        type="password"
                        error={
                          Boolean(errors.newPassword) &&
                          Boolean(touched.newPassword)
                        }
                        helperText={
                          Boolean(touched.newPassword) && errors.newPassword
                        }
                      ></Field>
                    </Grid>
                    <Grid item>
                      <Field
                        sx={fieldStyles}
                        name="confirmPassword"
                        label="Confirm New Password"
                        as={TextField}
                        variant="outlined"
                        required
                        size="small"
                        margin="dense"
                        type="password"
                        error={
                          Boolean(errors.confirmPassword) &&
                          Boolean(touched.confirmPassword)
                        }
                        helperText={
                          Boolean(touched.confirmPassword) &&
                          errors.confirmPassword
                        }
                      ></Field>
                    </Grid>

                    <Grid item>
                      <Button
                        sx={{
                          borderRadius: "8px",
                          fontSize: "1rem",
                          fontWeight: "700",
                          height: "2.5rem",
                          marginTop: "1rem",
                        }}
                        fullWidth
                        color="primary"
                        variant="contained"
                        disabled={!dirty || !isValid || callBack}
                        type="submit"
                        size="large"
                      >
                        {callBack ?
                          <CircularProgress size={20} />
                          :
                          'Submit'
                        }

                      </Button>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default ResetPassword;
